.add-database {
  display: flex;
  column-gap: 100px;
  padding: 64px 60px 50px 50px;

  .left {
    width: 100%;
  }

  h2 {
    color: #000;
    text-align: center;
    font-family: "IRANSansX";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }

  .left {
    display: flex;
    flex-direction: column;
    row-gap: 54px;

    .uppar {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        border-radius: 4px;
        border: 1px solid #000;
        padding: 9px 12px 5px 11.91px;
        color: #000;
        font-family: "IRANSansX";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        background: transparent;
      }
    }

    .nav {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 20px 23px;

      .nav-link {
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 137.096px;
        color: rgba(0, 0, 0, 0.4);
        text-align: center;
        font-family: "IRANSansX";
        font-size: 22.269px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        position: relative;
        border-radius: 2.784px;
        background: #fff;
        box-shadow: 0px 5.56733px 16.702px 0px rgba(0, 0, 0, 0.1);
        p {
          margin: 0px;
        }

        a {
          position: absolute;
          top: 9px;
          right: 12px;
        }
      }

      .nav-link:hover {
        border: 1px solid #000;
        color: #000;
      }
      .active {
        border: 1px solid #000;
        color: #000;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    row-gap: 54px;

    .uppar {
      display: flex;
      justify-content: space-between;

      .backButton {
        button {
          border-radius: 4px;
          border: 1px solid #2b54ff;
          padding: 9px 12px 5px 11.91px;
          color: #2b54ff;
          font-family: "IRANSansX";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          background: transparent;
        }
      }
    }

    .tab-content {
      display: flex;
      flex-direction: column;
      gap: 25px;
      h1 {
        color: #000;
        font-family: IRANSansX;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .card {
        padding: 14px 34px;
        border-radius: 8px;
        border: 1px solid #2b54ff;
        background: #fbfbfb;
        box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.15);
        cursor: pointer;
      }
    }

    // .tab-content {
    //   border-radius: 4px;
    //   border: 1px solid #2b54ff;
    //   background: #fff;
    //   box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.15);
    //   padding: 19px 23px 23px 23px;

    //   h4 {
    //     color: #000;
    //     font-family: "IRANSansX";
    //     font-size: 24px;
    //     font-style: normal;
    //     font-weight: 400;
    //     line-height: 100%;
    //     margin: 0px;
    //   }

    //   .detail {
    //     display: flex;
    //     flex-direction: column;
    //     row-gap: 13px;

    //     ol {
    //       padding-left: 20px;
    //       margin: 0px;
    //       display: flex;
    //       flex-direction: column;
    //       row-gap: 8px;

    //       li {
    //         color: #2b54ff;
    //         font-family: "IRANSansX";
    //         font-size: 16px;
    //         font-style: normal;
    //         font-weight: 500;
    //         line-height: 100%;
    //         /* 150% */
    //         cursor: pointer;
    //       }

    //       .viewMore {
    //         width: 100%;
    //         display: flex;
    //         justify-content: center;
    //         padding: 15px 0px 5px 0px;

    //         button {
    //           border-radius: 5px;
    //           background: #2b52f9;
    //           padding: 15px 25px;
    //           color: #fff;
    //           font-family: "IRANSansX";
    //           font-size: 15px;
    //           font-style: normal;
    //           font-weight: 400;
    //           line-height: 100%;
    //           border: none;
    //         }
    //       }
    //     }
    //   }
    // }
  }
}

@media screen and (max-width: 1550px) {
  .add-database {
    display: flex;
    column-gap: 40px;
    padding: 50px 40px;

    .left {
      width: 100%;
    }

    h2 {
      font-size: 22px;
    }

    .left {
      row-gap: 20px;

      .nav {
        gap: 18px;

        .nav-link {
          width: 100%;
          height: 120px;
          font-size: 20px;
        }
      }
    }

    .right {
      row-gap: 20px;

      .tab-content {
        padding: 15px;

        h4 {
          font-size: 20px;
        }

        .detail {
          row-gap: 10px;

          ol {
            row-gap: 5px;

            li {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .add-database {
    column-gap: 30px;
    padding: 30px;

    .left {
      width: 100%;
    }

    h2 {
      font-size: 20px;
    }

    .left {
      row-gap: 20px;

      .nav {
        gap: 18px;
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .right {
      row-gap: 20px;
      margin-top: 5px;

      .tab-content {
        padding: 15px;

        h4 {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .add-database {
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    padding: 50px 20px 20px 20px;

    .left {
      width: 100%;
      order: 2;
      padding-top: 20px;
    }

    // .right {
    //   width: 100%;

    //   .tab-content {
    //     padding: 0 !important;
    //     .card {
    //       padding: 10px 15px;
    //     }
    //   }
    // }

    h2 {
      font-size: 17px;
    }

    .left {
      row-gap: 15px;

      .uppar {
        flex-direction: column;
        row-gap: 5px;

        button {
          padding: 5px;
          font-size: 11px;
        }
      }

      .nav {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;

        .nav-link {
          height: 40px;
          font-size: 14px;
          width: 100%;

          a {
            position: absolute;
            top: 5px;
            right: 5px;

            img {
              width: 10px;
            }
          }
        }
      }
    }

    .right {
      row-gap: 15px;
      margin: 0px;

      .uppar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .backButton {
          button {
            border-radius: 4px;
            border: 1px solid #2b54ff;
            padding: 5px 5px 5px 5px;
            color: #2b54ff;
            font-family: "IRANSansX";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            background: transparent;
          }
        }
      }

      .tab-content {
        padding: 10px;

        h4 {
          font-size: 16px;
        }

        .detail {
          row-gap: 6px;

          ol {
            padding-left: 20px;
            row-gap: 5px;

            li {
              font-size: 15px;
            }

            .viewMore {
              width: 100%;
              display: flex;
              justify-content: center;
              padding: 15px 0px 5px 0px;

              button {
                border-radius: 5px;
                background: #2b52f9;
                padding: 10px 15px;
                color: #fff;
                font-family: "IRANSansX";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                border: none;
              }
            }
          }
        }
      }
    }
  }
}
