$primary: #535455;

$gray: #25292f;
.dashboard {
  height: 100vh;
  ::-webkit-scrollbar {
    display: none;
  }
  .bookmark-head{
    height: 100px;
    background-color: #25292f;
    display: flex;
    // justify-content: center;
    
    align-items: center;
    font-size: 35px;
    color: #ffffff;
    span{
      padding-left: 50px;
    }
  }
  .content {

    height: calc(100% - 90px);

    display: flex;
    // background-color: #535455;
    border-top: 1px solid #25292f;
    .sidebar {
      width: 17%;
      padding: 20px;
      display: flex;
      display: none;
      flex-direction: column;
      //   justify-content: space-between;
      //   height: 100vh;
      gap: 10px;
      height: 607px;
      overflow: scroll;
      background-color: #0d1219;
      .sidebarbtn {
        padding: 10px 15px;
        border: none;
        color: white;
        border-radius: 5px;
        text-align: center;
        background-color: #25292f;
        display: flex;
        justify-content: center;
      }

      .sidecard {
        padding: 10px 15px;
        display: flex;
        gap: 8px;
        color: white;
        background-color: #25292f;
        border-radius: 10px;
        align-items: flex-start;

        .icon {
          // width: 80px;
          //   height: 50px;
          background-color: white;
          padding: 5px;
          border-radius: 6px;
          width: 50px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .card_content {
          display: flex;
          justify-content: space-between;
          button {
            padding: 0 5px;
            color: white;
            border: none;
            border-radius: 5px;
          }
        }
      }
    }

    .sidebar2::-webkit-scrollbar {
      display: none;
    }

    .sidebar2 {
      width: 17%;
      padding: 20px;
      display: flex;
      // display: none;
      flex-direction: column;
      //   justify-content: space-between;
      //   height: 100vh;
      gap: 10px;
      // height: 800px;
      overflow: scroll;
      background-color: #40444b;
      transition: 0.5s ease-in-out;
      .sidebarbtn {
        padding: 10px 15px;
        border: none;
        color: white;
        border-radius: 5px;
        background-color: #292b2f;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
      }

      .sidecard {
        padding: 10px 15px;
        display: flex;
        gap: 15px;
        color: white;
        background-color: #25292f;
        border-radius: 10px;
        align-items: flex-start;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        .icon {
          // width: 80px;
          //   height: 50px;
          background-color: white;
          padding: 5px;
          border-radius: 6px;
          width: 40px;
          margin-top: 7px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .card_content {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: flex-start;

          button {
            padding: 0 5px;
            color: white;
            border: none;
            background-color: #78be06;
            border-radius: 5px;
          }
        }
      }
      .sidecard:hover {
        // background-color: ;
        // opacity: 0.9;
        // background-color: #292b2f;
        // transition: 0.5s ease-in-out;
        // // color: black;

        background-color: #000000;
        transition: 0.5s ease-in-out;
        transform: scale(1.07);
      }
    }

    .showsidebar2 {
      // position: absolute;
      // width: 100%;
      // transform: translateX(-400px);
      transform: translateX(0);

      transition: transform 0.5s ease;
    }

    .hidesidebar2 {
      position: absolute;
      transform: translateX(-400px);

      // transform: translateX(0);
      // width: 100%;
      transition: transform 0.5s ease;
    }

    .fullwidth {
      width: 100% !important;
      // transition: width 0.2s ease;
    }

    .desktoptoggle {
      padding: 30px 0 0 30px;
    }

    .right_content {
      width: 83%;
      // background-color: #0d1219;
      .sidebar_toggle {
        display: none;
      }
      .dashboard_content {
        height: 100%;
        background-color: #40444b;
        border-left: 1px solid #2f3136;
        display: flex;
        gap: 20px;
        padding: 0 40px 0px 20px;
        // max-height: 100vh;
        overflow-y: auto;

        .question_section {
          width: 43%;
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding-top: 20px;
          .bookmark-icon {
            // background-color: #78be06;

            svg {
              color: white;
              cursor: pointer;
              &:hover {
                color: greenyellow;
              }
            }
          }
          // h1 {
          //   color: #04103b;
          //   font-family: IRANSans(FaNum);
          //   font-size: 21.312px;
          //   font-style: normal;
          //   font-weight: 500;
          //   line-height: normal;
          // }

          .box {
            border-radius: 8px;
            // border: 1.332px solid #ebe8ff;
            // background: #fff;
            padding: 10px;
            .table {
              width: 100%;
            }

            button {
              padding: 0 5px;
              color: white;
              border: none;
              border-radius: 5px;
            }

            .difficulty_level_btn {
              padding: 0px 13px;
              background-color: #f72e52;
              border-radius: 5px;
              font-size: 14px;
              color: white;
              border: none;
            }

            .buttons {
              display: flex;
              gap: 10px;
              margin: 30px 0;
              flex-wrap: wrap;

              button {
                border-radius: 20px;
                padding: 0 10px;
                border: none;
                background-color: #d3d3d3;
              }
            }

            .telegram_icon {
              color: #797c7f;
              margin-bottom: 20px;
              display: flex;
              gap: 10px;
              align-items: center;
            }

            .accordion-btn {
              margin-bottom: 20px;
              .accordion-header {
                color: white;

                .accordion-button:not(.collapsed) {
                  color: #ffffff;
                  // background-color: #29008a;
                  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
                  // background-image: none !important;
                }
              }
              .accordion-item {
                border: none;
                outline: none;
                background-color: $gray;
                color: white;
              }
              .accordion-button {
                background-color: #191c22;
                padding: 5px;
                color: white;
                display: flex;

                gap: 10px;
              }

              .accordion-button::after {
                background-image: url("../assets/icon/arrow-up.svg");
              }

              .accordion-button:focus {
                border: none !important;
                box-shadow: none !important;
              }
            }

            h1 {
              width: 90%;
              font-size: 32px;
              color: white;
            }

            p {
              color: white;
              font-family: IRANSansX;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              margin-bottom: 0;
            }

            h3 {
              width: 90%;
              color: white;
              font-size: 22px;
            }

            span {
              color: white;
              font-family: IRANSansX;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
            }

            .half_width {
              width: 100%;
              // .table.csv-html-table.table.table-hover.table-custom-width {
              //   width: 100% !important;
              // }
              .table {
                th {
                  background-color: #25292f;
                  color: white;
                  border-bottom: 0;
                  border: 1px solid black;
                }
                td {
                  color: white;
                  background-color: #00000000;
                  border-color: black;
                  border: 1px solid black;
                  text-align: left;
                }
              }
            }

            .blue_box {
              //   width: 100%;
              border: 1px solid #25292f;
              margin: 10px 0 0 0;
              border-radius: 4px;

              .box_header {
                background-color: #25292f;
                padding: 10px 20px;
                display: flex;
                justify-content: space-between;
                h2 {
                  color: white;
                  font-family: IRANSans(FaNum);
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px;
                }

                span {
                  color: white;
                  font-family: IRANSans(FaNum);
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px;
                }
              }

              .box_details {
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                overflow: scroll !important;

                div {
                  justify-content: space-between;
                }
              }
            }
          }
        }

        .feedback_section {
          padding: 40px 0 0 20px;
          width: 70%;
          border-left: 1px solid #25292f;

          height: 100%;
          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            div {
              display: flex;
              gap: 10px;

              // flex-wrap: wrap;
            }
          }

          .box {
            // border: 1px solid #ebe8ff;
            // border-radius: 8px;
            // padding: 25px;
            margin: 5px 0;
            min-height: 500px;

            h1 {
              color: #444;
              font-family: IRANSansX;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              margin-bottom: 15px;
            }
            .editor {
              padding: 10px 0 0 0;
              width: 100%;
              height: 400px;
              background-color: #191c22;
              border: 1px solid $gray;
            }
            .buttons {
              display: flex;
              gap: 20px;
              .submit_feedback {
                display: flex;
                gap: 10px;
                border: none;
                color: white;
                background-color: #25292f;
                padding: 5px 10px;
                // border-radius: 5px;
                align-items: center;
              }

              .run_query {
                // background: red;
                transition-property: width;
                transition-duration: 1s;
                padding: 10px;
                width: 120px;
                // height: 50px;
                background-color: $gray;
                border: none;
                font-size: 14px;
                color: white;
              }

              .run_query:hover {
                background-color: #f72e52;
                // font-size: 14px;
                width: 150px;
                // transition: width;
              }

              margin-bottom: 20px;
            }

            textarea {
              resize: none;
              border: none;
              outline: none;
              caret-color: white;
              height: 100%;
              width: 100%;
              min-height: 400px;
              color: white;
              padding: 10px;
              display: block;
              background-color: #191c22;
              border: 1px solid $gray;
              &::placeholder {
                color: white;
              }
            }

            .buttons_bottom {
              border: 1px solid $gray;
              display: flex;
              justify-content: flex-end;
              background-color: #191c22;

              p {
                display: flex;
                align-items: center;
                padding: 0 0 0 20px;
                color: white;
                margin: 0 !important;
              }

              .button_ {
                margin: 0;

                .unlock {
                  margin-left: 20px;
                  background-color: #43dde7;
                  font-size: 14px;

                  transition-property: width;
                  transition-duration: 1s;
                }
                .unlock:hover {
                  width: 160px;
                }

                button {
                  padding: 10px;
                  width: 130px;
                  // height: 50px;
                  background-color: $gray;
                  border: none;
                  font-size: 14px;
                  color: white;
                }
              }
            }
          }

          .result_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
            h1 {
              color: white;
              font-family: IRANSansX;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }

            div {
              display: flex;
              gap: 10px;
            }
          }
          .outputStatus {
            padding: 20px;
            display: flex;
            justify-content: center;

            p {
              color: white;
              font-weight: 400;
              font-size: 23px;
            }
            .thumbsIcon {
              color: white;
              position: relative;
              top: -6px;
              left: 6px;
            }
          }
          .result::-webkit-scrollbar {
            display: none;
          }
          .result {
            p {
              margin-bottom: 0;
              font-family: IRANSansX;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }
            .table {
              th {
                background-color: #25292f;
                color: white;
                border-bottom: 0;
                border: 1px solid black;
              }
              td {
                color: white;
                background-color: #00000000;
                border-color: black;
                border: 1px solid black;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

.submit_modal {
  .modal-content {
    background-color: transparent;
    color: white;
  }
  .modal-header {
    border-bottom: none;
    .logo {
      width: 50px;
      height: 50px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    // padding-top: 0;
    background-color: $gray;
    border-radius: none !important;
  }
  .modal-body {
    // background-color: $gray;
    background-color: $gray;
    border: none !important;
    outline: none !important;
    padding-top: 10;
    h3 {
      margin-bottom: 30px;
    }

    h4 {
      font-size: 17px;
      color: #e73c7e;
      font-weight: 800;
      margin-bottom: 20px;
    }
    p {
      display: flex;
      gap: 10px;
      margin: 5px;
      align-items: center;
    }
  }

  .modal-footer {
    background-color: transparent;
    padding: 10px 0 0 0;
    border-top: none;
    button {
      width: 100%;
      background-color: #f72e52;
      border: none;
      padding: 15px 0;
      color: white;
      border-radius: 5px;
    }
    button:hover {
      width: 100%;
    }
  }
}

.unlock_modal {
  .modal-content {
    background-color: transparent;
    color: white;
  }
  .modal-header {
    border-bottom: none;
    .logo {
      width: 50px;
      height: 50px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    // padding-top: 0;
    background-color: $gray;
    border-radius: none !important;
  }
  .modal-body {
    // background-color: $gray;
    background-color: $gray;
    border: none !important;
    outline: none !important;
    padding-top: 10;
    h3 {
      margin-bottom: 30px;
    }

    h4 {
      font-size: 17px;
      color: #e73c7e;
      font-weight: 800;
    }
    p {
      display: flex;
      gap: 10px;
      margin: 5px;
      align-items: center;
    }
  }

  .modal-footer {
    background-color: transparent;
    padding: 10px 0 0 0;
    border-top: none;
    button {
      width: 100%;
      background-color: #f72e52;
      border: none;
      padding: 15px 0;
      color: white;
      border-radius: 5px;
    }
    button:hover {
      width: 100%;
    }
  }
}

.show_solution_modal {
  // width: 100%;

  .modal-content {
    width: 1000px !important;
    // background-color: yellow;
    color: white;
  }
  .modal-header {
    border-bottom: none;
    .logo {
      width: 50px;
      height: 50px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    // padding-top: 0;
    background-color: $gray;
    border-radius: none !important;
  }
  .modal-body {
    // background-color: $gray;
    background-color: $gray;
    border: none !important;
    outline: none !important;
    padding-top: 10;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    textarea {
      background-color: $primary;
      color: white;
    }

    h4 {
      font-size: 17px;
      color: white;
      font-weight: 600;
      padding-bottom: 7px;
    }
  }

  .modal-footer {
    background-color: transparent;
    padding: 10px 0 0 0;
    button {
      width: 100%;
      background-color: #f72e52;
      border: none;
      padding: 15px 0;
      color: white;
      border-radius: 5px;
    }
  }
}

@media screen and (max-width: 1800px) {
  .dashboard {
    .content {
      .sidebar {
        width: 20%;

        .sidebarbtn {
        }

        .sidecard {
          .icon {
            img {
            }
          }

          .card_content {
            font-size: 14px;
            button {
              border-radius: 3px;
            }
          }
        }
      }

      .sidebar2 {
        width: 20%;

        .sidebarbtn {
        }

        .sidecard {
          .icon {
            img {
            }
          }

          .card_content {
            font-size: 14px;
            button {
            }
          }
        }
      }

      .right_content {
        width: 80%;
        .dashboard_content {
          .question_section {
            .box {
              h1 {
                // font-size: 24px;
              }

              h3 {
                // width: 100%;
                // font-size: 17px;
                // font-weight: 400;
              }

              p {
                // width: 100%;
                // font-size: 17px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .dashboard {
    .content {
      // .sidebar {
      //   // width: 22%;
      //   padding: 10px;
      //   display: flex;
      //   flex-direction: column;
      //   //   justify-content: space-between;
      //   //   height: 100vh;
      //   gap: 10px;
      //   background-color: #0d1219;
      //   // background-color: #78be06;
      //   .sidebarbtn {
      //     padding: 10px 10px;
      //     border: none;
      //     color: white;
      //     border-radius: 5px;
      //     background-color: #25292f;
      //   }

      //   .sidecard {
      //     padding: 10px;
      //     display: flex;
      //     gap: 8px;
      //     color: white;
      //     background-color: #25292f;
      //     border-radius: 10px;
      //     align-items: flex-start;

      //     .icon {
      //       width: 50px;
      //       //   height: 50px;
      //       background-color: white;
      //       padding: 5px;
      //       border-radius: 6px;
      //       img {
      //         width: 100%;
      //         height: 100%;
      //       }
      //     }

      //     .card_content {
      //       font-size: 14px;
      //       button {
      //         padding: 0 5px;
      //         border: none;
      //         background-color: #78be06;
      //         border-radius: 5px;
      //       }
      //     }
      //   }
      // }

      .sidebar2 {
        // width: 22%;
        padding: 10px;

        flex-direction: column;
        //   justify-content: space-between;
        //   height: 100vh;

        // height: 600px;

        gap: 10px;
        background-color: #40444b;
        // background-color: #78be06;
        .sidebarbtn {
          padding: 10px 10px;
          border: none;
          color: white;
          border-radius: 5px;
          background-color: #25292f;
          font-size: 14px;

          .side_icon {
            width: 20px;
            height: 20px;
          }
        }

        .sidecard {
          padding: 10px;
          display: flex;
          gap: 8px;
          color: white;
          background-color: #25292f;
          border-radius: 10px;
          align-items: flex-start;

          .icon {
            // width: 50px;
            //   height: 50px;
            background-color: white;
            padding: 5px;
            border-radius: 6px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .card_content {
            font-size: 14px;
            button {
              padding: 0 5px;
              border: none;
              background-color: #78be06;
              border-radius: 5px;
            }
          }
        }
      }

      .right_content {
        // width: 78%;
        .dashboard_content {
          .question_section {
            width: 45%;
            .box {
              h1 {
                font-size: 24px;
              }

              h3 {
                width: 100%;
                font-size: 17px;
                font-weight: 400;
              }

              p {
                width: 100%;
                font-size: 17px;
              }

              .half_width {
                .table {
                  th {
                    background-color: #25292f;
                    color: white;
                    border-bottom: 0;
                    font-size: 12px;
                    border: 1px solid black;
                  }
                  td {
                    color: white;
                    background-color: #00000000;
                    border-color: black;
                    font-size: 13px;
                    // padding: 10px;
                    border: 1px solid black;
                    text-align: left;
                  }
                }
              }
            }
          }

          .feedback_section {
            width: 55%;
            .box {
              min-height: 300px;
              padding: 0;
              .buttons {
                gap: 6px;
                font-size: 14px;
                // flex-wrap: wrap;
              }
              .editor {
                height: 300px;
              }

              .buttons_bottom {
              }
            }

            .result {
              .table {
                font-size: 13px;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .dashboard {
    .content {
      .sidebar {
        // width: 20%;

        .sidebarbtn {
        }

        .sidecard {
          .icon {
            img {
            }
          }

          .card_content {
            .card_footer {
              display: flex;
              column-gap: 50px;
              button {
              }
            }
          }
        }
      }

      .sidebar2 {
        width: 22%;

        .sidebarbtn {
          font-size: 13px;
        }

        .sidecard {
          .icon {
            img {
            }
          }

          .card_content {
            font-size: 12px;
            .card_footer {
              display: flex;
              column-gap: 100px;
              button {
              }
            }
          }
        }
      }

      .right_content {
        width: 78%;
        .dashboard_content {
          padding-left: 10px;
          padding-right: 20px;
          .question_section {
            padding-top: 0;
            .box {
              h1 {
                font-size: 20px;
              }

              h3 {
                // width: 100%;
                // font-size: 16px;
                // font-weight: 400;
              }

              p {
                // width: 100%;
                font-size: 16px;
              }
            }
          }

          .feedback_section {
            .box {
              .buttons {
                flex-wrap: wrap;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .dashboard {
    // width: 100%;
    .content {
      width: 100%;

      .sidebar {
        // display: none;
        // width: 20%;
        display: flex;

        .sidebarbtn {
        }

        .sidecard {
          gap: 20px;
          .icon {
            width: 50px;
            margin-top: 8px;
            display: none;
            img {
            }
          }

          .card_content {
            font-size: 18px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: flex-start;
            .card_footer {
              display: flex;
              column-gap: 100px;
              button {
              }
            }
          }
        }
      }

      .sidebar2 {
        display: none;
      }

      .desktoptoggle {
        // padding: 30px 0 0 30px;
        display: none;
      }

      .showsidebar {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 15;
        transform: translateX(0);
        transition: transform 0.5s ease;
      }

      .hidesidebar {
        position: absolute;
        transform: translateX(-600px);
        width: 100%;
        transition: transform 0.5s ease;
      }

      .right_content {
        // width: 80%;

        .sidebar_toggle {
          padding: 20px 0 0 20px;
          display: block;
        }
        width: 100%;
        .dashboard_content {
          width: 100%;
          border: none;
          padding: 20px;
          flex-direction: column;
          .question_section {
            width: 100%;
            padding-top: 0;
            .box {
              padding: 20px 0 20px 0;

              .half_width {
                .box_details {
                  width: 100%;
                }
              }

              h1 {
                // font-size: 24px;
              }

              h3 {
                // width: 100%;
                // font-size: 17px;
                // font-weight: 400;
              }

              p {
                // width: 100%;
                // font-size: 17px;
              }
            }
          }

          .feedback_section {
            width: 100%;
            padding: 0;
            border: none;

            .box {
              .buttons {
                width: 100%;
                flex-direction: column;
                justify-content: center;
                // align-items: flex-start;
                .submit_feedback {
                  width: 100%;
                  justify-content: center;
                }
              }
              .buttons_bottom {
                p {
                  font-size: 12px;
                  padding: 10px 0 0 10px;
                  align-items: flex-start;
                }
                .button_ {
                  display: flex;
                  justify-content: end;
                  flex-direction: column;
                  gap: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
