// Primary color blue
@font-face {
  font-family: "JK Abode";
  src: url("../../../../public/font/JKAbode-Demo.ttf");
}
@font-face {
  font-family: "IRANSansX";
  src: url("../../../../public/font/IRANSans\ 400.ttf");
}
@font-face {
  font-family: "Jomhuria";
  src: url("../../../../public/font/Jomhuria-Regular.ttf");
}
@font-face {
  font-family: "Volkhov";
  src: url("../../../../public/font/Volkhov-Regular.ttf");
}

@font-face {
  font-family: "Vollkorn";
  src: url("../../../../public/font/Vollkorn-VariableFont_wght.ttf");
}
@font-face {
  font-family: "IRANSans(FaNum)";
  src: url("../../../../public/font/IRANSans\(FaNum\).ttf");
}

@font-face {
  font-family: "Inter";
  src: url("../../../../public/font/Inter-Regular.ttf");
}
@font-face {
  font-family: "Lato";
  src: url("../../../../public/font/Lato-Regular.ttf");
}

$primary: black;

.User_Dashboard {
  display: flex;
  ::-webkit-scrollbar {
    display: none;
  }
  .Sidebar {
    // width: 100%;
    position: relative;
    overflow: scroll;
    position: fixed;

    // position: sticky;
    // display: none ;
    width: 316px;
    display: flex;
    height: 100%;
    transition: transform 0.3s ease;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    background-color: $primary;
    // height: 100vh;
    padding: 20px 0 20px 12px;

    .logo {
      margin-bottom: 30px;
      width: 90%;
      display: flex;
      justify-content: space-between;

      img {
        width: 100%;
      }

      .cross {
        display: none;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .container {
        display: flex;
        align-items: center;
        padding: 10px 0;
        gap: 20px;
        padding-left: 50px;
        cursor: pointer;
      }

      .active_container {
        display: flex;
        align-items: center;
        padding: 10px 0;
        gap: 20px;
        padding-left: 50px;
        background-color: white;
        border-radius: 13.32px 0px 0px 13.32px;
        cursor: pointer;
      }

      .btn {
        color: white;
        border: none;
        outline: none;
        background: transparent;
        padding: 0 !important;
        font-size: 20px;
      }

      .active_btn {
        color: $primary;
        border: none;
        outline: none;
        background: transparent;
        font-size: 20px;
      }
    }

    .progress_bar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 17px;
      padding-right: 8px;
      margin-top: 17px;

      .upgrate_now {
        width: 111.055px;
        height: 145.605px;
        border-radius: 12.339px;
        border: 0.346px solid #e2def9;
        background: #fff;
        box-shadow: -17.27521px 13.57338px 27.76374px 0px rgba(27, 29, 66, 0.14);
        padding: 23px 12px 14px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        // align-items: center;
        justify-content: space-between;

        .upgrade_img {
          width: 72.186px;
          height: 57.996px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        h1 {
          color: #263156;
          text-align: center;
          font-family: Nunito;
          font-size: 8.638px;
          font-weight: 700;
        }

        button {
          color: #fff;
          text-align: center;
          // font-family: Nunito;
          font-size: 8.638px;
          font-weight: 700;
          border: none;
          outline: none;
          padding: 5px 15px;
          border-radius: 14.338px;
          background: #ff8057;
          cursor: pointer;
        }
      }

      .your_progress {
        border-radius: 7.423px;
        background: var(--neutral-white, #fff);
        padding: 10px 14.845px;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 5px;

        h1 {
          color: #000;

          font-family: Lato;
          font-size: 10px;
          font-weight: 700;
          line-height: 14.845px;
        }

        h2 {
          color: black;
          font-family: IRANSansX;
          font-size: 12.99px;
          font-weight: 600;
        }
        input {
          // width: 274px;
          margin: 0;
          cursor: pointer;
        }
      }

      // img:last-child {
      //   // width: 100%;
      // }

      // .upgrade {
      //   box-shadow: -17.27521px 13.57338px 27.76374px 0px rgba(27, 29, 66, 0.14);
      // }
    }
  }

  .Content {
    // height: 100vh;
    // height: calc(100% - 90px);
    margin-left: 316px;

    .back-button {
      margin-left: 40px;
    }
    .no_question {
      // background-color: #07c290;
      font-size: 32px;
      margin-top: 20%;
      // font-family: ;
      text-align: center;
      font-weight: 600;
    }
    // width: 100%;
    width: calc(100% - 316px);

    // border: 1px solid red;
    .AI_Content {
      // border: 1px solid red;

      .ai_section {
        padding: 40px;
        display: flex;
        gap: 20px;
        max-height: 85vh;
        overflow-y: auto;
        margin-top: 95px;

        .instruct_section {
          width: 50%;

          .modal-content {
            .modal-body {
              .dropdown {
                margin-top: 20px;
                select {
                  width: 100%;
                  appearance: none;
                  padding: 13.5px 25px;
                  border-radius: 8px;
                  border: 1px solid #202225;
                  background: #fff;
                  background-image: url("../../../assets/icons/drop-down.png");
                  background-repeat: no-repeat;
                  background-position: calc(100% - 23px) center;
                  background-size: 23px 23px;
                  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.15);
                  // color: #d6d5d5;
                  font-family: IRANSansX;
                  font-size: 20px;
                  font-weight: 300;

                  &:focus {
                    // border-color: #202225; /* Set the border color to blue when focused */
                    // border-width: 1px;
                    outline: #202225; /* Remove the default focus outline */
                    outline-width: 1px;
                    outline-style: solid;
                    // border: none;
                  }

                  &::-ms-expand {
                    display: none;
                  }

                  option:hover {
                    background-color: #000;
                  }
                }

                option {
                  // color: #d6d5d5;
                  font-family: IRANSansX;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: normal;
                }
              }
            }
          }

          .modal-body {
          }

          h1 {
            color: #04103b;
            font-family: IRANSans(FaNum);
            font-size: 21.312px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          textarea {
            border: 1px solid #ebe8ff;
            border-radius: 8px;
            padding: 25px;
            min-height: 280px;
            margin: 10px 0 0 0;
            width: 100%;
            resize: none;
            outline: none;
          }

          .btn_container {
            display: flex;
            justify-content: space-between;

            .generate {
              transition-property: width;
              transition-duration: 1s;
              padding: 10px;
              width: 120px;
              // height: 50px;
              background-color: #25292f;
              border: none;
              font-size: 14px;
              color: white;
            }

            .data_source {
              transition-property: width;
              transition-duration: 1s;
              padding: 10px;
              width: 160px;
              // height: 50px;
              background-color: #25292f;
              border: none;
              font-size: 14px;
              color: white;
            }

            .data_source:hover {
              background-color: #f72e52;
              // font-size: 14px;
              width: 180px;
            }

            .generate:hover {
              background-color: #f72e52;
              // font-size: 14px;
              width: 150px;
            }
          }

          .dropdown {
            margin-top: 20px;
            select {
              width: 100%;
              appearance: none;
              padding: 13.5px 25px;
              border-radius: 8px;
              border: 1px solid #202225;
              background: #fff;
              background-image: url("../../../assets/icons/drop-down.png");
              background-repeat: no-repeat;
              background-position: calc(100% - 23px) center;
              background-size: 23px 23px;
              box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.15);
              // color: #d6d5d5;
              font-family: IRANSansX;
              font-size: 20px;
              font-weight: 300;

              &:focus {
                // border-color: #202225; /* Set the border color to blue when focused */
                // border-width: 1px;
                outline: #202225; /* Remove the default focus outline */
                outline-width: 1px;
                outline-style: solid;
                // border: none;
              }

              &::-ms-expand {
                display: none;
              }

              option:hover {
                background-color: #000;
              }
            }

            option {
              // color: #d6d5d5;
              font-family: IRANSansX;
              font-size: 16px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
            }
          }

          .half_width {
            .blue_box {
              width: 100%;
              border: 1px solid $primary;
              margin: 10px 0 0 0;
              border-radius: 4px;

              .box_header {
                background-color: $primary;
                padding: 10px 20px;
                display: flex;
                justify-content: space-between;
                h2 {
                  color: white;
                  font-family: IRANSans(FaNum);
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px;
                }

                span {
                  color: white;
                  font-family: IRANSans(FaNum);
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px;
                }
              }

              .box_details {
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                overflow: scroll !important;

                div {
                  justify-content: space-between;
                }

                table {
                }
              }
            }

            .table {
              text-align: center;
              th {
              }
              td {
                // width: 50%;
                // width: 120px;
              }
            }
          }

          .result {
            span {
              color: #444;
              font-family: IRANSansX;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              width: 500px;
            }

            p {
              // color: #d4d5db;
              font-family: IRANSansX;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              margin-bottom: 0;
            }
          }
        }

        .generate_section {
          width: 50%;

          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h1 {
              color: #04103b;
              font-family: IRANSans(FaNum);
              font-size: 21.312px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            div {
              display: flex;
              gap: 10px;
            }
          }

          .box {
            border: 1px solid #ebe8ff;
            border-radius: 8px;
            padding: 25px;
            margin: 5px 0;
            min-height: 500px;
            background-color: #191c22;
            h1 {
              color: white;
              // color: #444;
              font-family: IRANSansX;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              margin-bottom: 15px;
            }

            textarea {
              resize: none;
              border: none;
              outline: none;
              height: 100%;
              width: 100%;
              min-height: 400px;
              caret-color: white;
              background-color: #191c22;
              color: white;

              &::placeholder {
                // color: #d4d5db;
              }
            }
          }

          // .result {
          //   // background-color: #07c290;

          //   .table {
          //     text-align: center;
          //     background-color: #000 !important;
          //     color: white;
          //     th {
          //     }
          //     td {
          //       // width: 50%;
          //       // width: 120px;
          //     }
          //   }
          // }

          .btn_container {
            display: flex;
            justify-content: end;
            align-items: center;
          }
        }
      }
    }
    // DataBase Content
    .Database_Content {
      .database_section {
        margin-top: 95px;
        padding: 40px 50px;
        overflow-y: auto;

        .list_heading {
          h1 {
            font-family: "IRANSansX", sans-serif;
            font-size: 24px;
            font-style: normal;
            margin-bottom: 28px;
            margin-top: 68px;
            font-weight: 600;
            line-height: 24px;
          }
        }

        .db_cards {
          display: flex;
          flex-wrap: wrap;
          gap: 45px;
        }
      }

      .database_details {
        // background-color: red;
        max-height: 80vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 14px;
        padding: 20px 47px;
        margin-top: 95px;
        .introduction {
          max-width: 795px;
          // background-color: red;

          h1 {
            // background-color: red;
            color: #444;
            font-family: "IRANSansX", sans-serif;
            font-size: 24px !important;
            font-weight: 600;
            line-height: 24px;
            margin-top: 20px;
          }

          p {
            color: #797d8c;
            margin-top: 10px !important;
            font-family: IRANSansX;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }

        .description {
          max-width: 795px;
          h2 {
            color: #444;
            font-style: normal;
            font-family: "IRANSansX", sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            text-transform: capitalize;
          }

          p {
            color: #797d8c;
            font-family: "IRANSansX", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px; /* 150% */
            margin-top: 10px;
            word-break: break-all;
            text-transform: capitalize;
          }
        }

        .list_of_table {
          max-width: 795px;
          // width: 100%;
          height: auto;
          padding: 20px 17px;
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.5);
          background: #f4f4f4;

          h3 {
            color: #444;
            font-family: "IRANSansX", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
            margin-bottom: 20px;
          }

          .buttons {
            display: flex;
            flex-wrap: wrap;
            gap: 11px;
          }
        }
      }

      .db_button {
        button {
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          background: #eee;
          width: 135px;
          height: 39px;

          color: #797d8c;
          font-family: "IRANSansX", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }
      }
    }

    .Dashboard_Content {
      .dashboard_content {
        display: flex;
        gap: 20px;
        padding: 40px;
        // max-height: 100vh;
        overflow-y: auto;

        .question_section {
          width: 40%;
          display: flex;
          flex-direction: column;
          gap: 10px;

          h1 {
            color: #04103b;
            font-family: IRANSans(FaNum);
            font-size: 21.312px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .box {
            border-radius: 8px;
            border: 1.332px solid #ebe8ff;
            // background: #fff;
            padding: 10px;
            border: none;

            p {
              color: #444;
              font-family: IRANSansX;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
              margin-bottom: 0;
            }

            span {
              color: #444;
              font-family: IRANSansX;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }

            .half_width {
              width: 100%;
              // .table.csv-html-table.table.table-hover.table-custom-width {
              //   width: 50% !important;
              //   margin-left: 7px;
              // }

              table {
                tbody {
                  // background-color: #07c290 !important;

                  tr {
                    td {
                      // border: 1px solid red !important;
                      // padding-right: 0;
                    }
                  }
                }
              }
            }

            .blue_box {
              width: 100%;
              border: 1px solid $primary;
              margin: 10px 0 0 0;
              border-radius: 4px;

              .box_header {
                background-color: $primary;
                padding: 10px 20px;
                display: flex;
                justify-content: space-between;
                h2 {
                  color: white;
                  font-family: IRANSans(FaNum);
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px;
                }

                span {
                  color: white;
                  font-family: IRANSans(FaNum);
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px;
                }
              }

              .box_details {
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                overflow: scroll !important;

                div {
                  justify-content: space-between;
                }
              }
            }
          }
        }

        .feedback_section {
          width: 70%;

          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            div {
              display: flex;
              gap: 10px;

              // flex-wrap: wrap;
            }
          }

          .box {
            border: 1px solid #ebe8ff;
            border-radius: 8px;
            padding: 25px;
            margin: 5px 0;
            min-height: 500px;
            border: none;

            h1 {
              color: #444;
              font-family: IRANSansX;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              margin-bottom: 15px;
            }

            textarea {
              resize: none;
              border: none;
              outline: none;
              height: 100%;
              width: 100%;
              min-height: 400px;

              &::placeholder {
                color: #d4d5db;
              }
            }
          }

          .result_header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h1 {
              color: #444;
              font-family: IRANSansX;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            }

            div {
              display: flex;
              gap: 10px;
            }
          }

          .result {
            p {
              margin-bottom: 0;
              font-family: IRANSansX;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
      }
    }

    .History_Content {
      .Book_mark {
        background-color: #40444b;
      }
      .history_section {
        padding: 40px 50px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 95px;

        overflow-y: auto;
        height: 100vh;

        .recently_viewed_questions {
          .history_heading {
            h1 {
              color: #04103b;
              margin-bottom: 20px;
              font-family: "IRANSans(FaNum)", sans-serif;
              font-size: 21.312px;
              font-weight: 700;
            }
          }

          .history_cards {
            display: flex;
            flex-direction: column;
            gap: 20px;
          }
        }

        .saved_questions {
          .history_heading {
            h1 {
              color: #04103b;
              margin-bottom: 20px;
              font-family: "IRANSans(FaNum)", sans-serif;
              font-size: 21.312px;
              font-weight: 700;
            }
          }

          .history_cards {
            display: flex;
            flex-direction: column;
            gap: 20px;
          }
        }
      }
    }

    .LeaderBoard_Content {
      .leaderboard_topbar {
        display: none;
      }
      .leader_board {
        .winning_section {
          position: relative;
          background-color: #000000;
          // background: linear-gradient(181deg, black 4.45%, #0025bd 99.99%);
          height: 130px;
          filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.3));
          color: white;
          text-align: center;

          .cone_left {
            width: 100px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .cone_right {
            width: 120px;
            position: absolute;
            top: 0;
            right: 0;
          }

          .winners {
            display: flex;
            gap: 100px;
            width: 100%;
            justify-content: center;
            padding: 20px 0;

            .winner_one {
              position: relative;
              .winner_img {
                width: 70px;
                height: 70px;
                margin-bottom: 6px;
                position: relative;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
                .crown {
                  position: absolute;
                  width: 30px;
                  height: 30px;
                  object-fit: cover;
                  top: -15px;
                  left: 0;
                  z-index: 2;
                }
              }

              .winner_name {
                color: #fff;
                font-family: Inter;
                font-size: 10.088px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .winner_points {
                color: #fff;
                font-family: Inter;
                font-size: 11.706px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
            }

            .winner_two {
              .winner_img {
                width: 70px;
                height: 70px;
                margin-bottom: 6px;
                position: relative;

                img {
                  width: 100%;
                  height: 100%;
                }

                .crown {
                  position: absolute;
                  width: 30px;
                  height: 30px;
                  object-fit: cover;
                  top: -15.5px;
                  right: 0;
                  z-index: 2;
                }
              }

              .winner_name {
                color: #fff;
                font-family: Inter;
                font-size: 10.088px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .winner_points {
                color: #fff;
                font-family: Inter;
                font-size: 11.706px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
            }

            .winner_three {
              .winner_img {
                width: 70px;
                height: 70px;
                margin-bottom: 6px;
                position: relative;
                // border: 1px solid yellow;
                img {
                  width: 100%;
                  height: 100%;
                }

                .crown {
                  position: absolute;
                  width: 30px;
                  height: 30px;
                  object-fit: cover;
                  top: -15px;
                  right: 0;
                  z-index: 2;
                }
              }
              .winner_name {
                color: #fff;
                font-family: Inter;
                font-size: 10.088px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .winner_points {
                color: #fff;
                font-family: Inter;
                font-size: 11.706px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
            }
          }
        }

        .winner_details {
          padding: 15px 64px 30px 34px;

          .heading {
            display: flex;
            justify-content: space-between;

            h1 {
              font-family: IRANSans(FaNum);
              font-size: 36px;
              font-style: normal;
              font-weight: 500;
            }
          }

          .winner_list {
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            gap: 7px;
            max-height: 68vh;
            overflow-y: auto;
          }
        }
      }
    }

    .Subscription_Content {
      .card_section {
        margin-top: 95px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        gap: 70px;
        overflow-y: auto;
        height: 100%;

        .heading {
          text-align: center;

          h1 {
            font-family: "Poppins" sans-serif;
            font-size: 50px;
            font-style: normal;
            font-weight: 700;

            span {
              color: $primary;
              text-align: center;
              font-family: "Poppins", sans-serif;
              font-size: 48px;
              font-weight: 600;
              text-transform: uppercase;
            }
          }
        }

        .cards {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 40px;

          .subscription_card {
            border-radius: 7.144px;
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0.08) 0%,
              rgba(255, 255, 255, 0.08) 100%
            );

            box-shadow: 0px 7.14428px 21.43283px 0px rgba(0, 0, 0, 0.2);
            width: 25%;
            display: flex;
            flex-direction: column;
            padding: 50px 0 150px 0;
            text-align: center;

            .hr {
              stroke-width: 0.893px;
              stroke: #000;
              opacity: 0.4;
              width: 100%;
              margin: 0;
            }

            .top_card {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 30px;

              .card_heading {
                h1 {
                  font-family: "Poppins", sans-serif;
                  font-size: 28.577px;
                  font-weight: 600;
                }

                p {
                  font-family: "Poppins", sans-serif;
                  font-size: 13.396px;
                  font-style: normal;
                  font-weight: 400;
                }
              }

              .price_and_buttons {
                display: flex;
                flex-direction: column;
                gap: 20px;
                justify-content: center;
                align-items: center;

                .price {
                  h1 {
                    font-family: "Poppins", sans-serif;
                    font-size: 35.721px;
                    font-style: normal;
                    font-weight: 500;

                    sup {
                      font-family: "Poppins", sans-serif;
                      font-size: 16px;
                      font-weight: 400;
                      vertical-align: super;
                    }

                    span {
                      font-family: "Poppins", sans-serif;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                    }
                  }
                }

                .buttons {
                  .card_button {
                    padding: 8px 15px;
                    background-color: $primary;
                    border: none;
                    outline: none;
                    border-radius: 5px;
                    color: #fff;
                    text-align: center;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                  }

                  p {
                    margin: 10px;
                  }
                }
              }
            }

            .card_content {
              text-align: left;
              padding: 0 10px;
              display: flex;
              flex-direction: column;
              gap: 20px;
              margin-top: 40px;

              .tick_line {
                line-height: 10px;

                span {
                  margin-left: 10px;
                  color: #000;
                  font-family: "Poppins", sans-serif;
                  font-size: 12.502px;
                  font-style: normal;
                  font-weight: 400;
                }
              }

              .card_description {
                border-radius: 3.572px;
                border: 0.893px solid var(--1, #379eff);
                background: #fcfcfc;
                padding: 10px;

                h1 {
                  font-family: "Poppins", sans-serif;
                  font-size: 16px;
                  font-weight: 600;
                }

                P {
                  margin-left: 20px;
                  margin-top: 10px;
                  font-family: "Poppins", sans-serif;
                  font-size: 16px;
                  font-weight: 500;
                }
              }
            }
          }
        }

        .question {
          color: #000;

          text-align: center;
          font-family: "Poppins", sans-serif;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          span {
            font-weight: 500;
          }
          text-align: center;
          width: 22%;
          margin-top: -70px;
        }
      }
    }

    .DiscussionForm_Content {
      .discussion_form_section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 95px;
        gap: 110px;

        img {
          width: 100%;
        }
      }

      .discussion_community {
        padding: 36px 66px 57px 47px;

        // height: 100%;
        margin-top: 95px;
        .ask_question_btn {
          text-align: right;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .askbtn {
            margin-bottom: 7px;
          }
        }

        h1 {
          font-family: IRANSans(FaNum);
          // background-color: red;
          font-size: 21.312px;
          font-style: normal;
          font-weight: 500;
        }

        .discussion_cards {
          display: grid;
          grid-template-columns: auto auto auto;
          gap: 28px 55px;
          max-height: 75vh;
          overflow-y: auto;
        }

        .load_more_btn {
          text-align: center;
          margin-top: 14px;
        }
      }

      .discussion_details_main_sec {
        // padding: 40px 50px;
        padding: 36px 93px 20px 45px;
        margin-top: 95px;
        .ask_question_btn {
          h1 {
            // font-family: IRANSans(FaNum);
            // background-color: red;
            font-size: 21.312px;
            font-style: normal;
            font-weight: 600;
          }
          text-align: right;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .askbtn {
            margin-bottom: 7px;
          }
        }

        .discussion_details_sec {
          width: 100%;
          max-height: 85vh;
          overflow-y: auto;
          display: flex;
          gap: 45px;

          // Question Box section ------------------------------------------------------------------------------------->>>
          .question_box {
            padding: 17px 25px;
            border-radius: 8px;
            border: 1.332px solid #ebe8ff;
            background: #fff;

            .question_box_heading {
              color: #444;
              font-family: "IRANSansX";
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px; /* 150% */
              padding-bottom: 2px;
              .user {
                display: flex;
                align-items: center;
                column-gap: 6px;
                .profile_img {
                  width: 35px;
                  height: 35px;
                  border-radius: 50% !important;
                  overflow: hidden; /* Ensure content doesn't overflow the container */
                  background-color: black;

                  img {
                    width: 100%; /* Adjust the width as needed */
                    height: 100%; /* Adjust the height as needed */
                    object-fit: cover; /* Ensure the image covers the entire container */
                    border-radius: 50%; /* Keep border-radius consistent for a circular image */
                  }
                }
              }
            }

            .question_box_content {
              color: #797d8c;
              font-family: "IRANSansX";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */
            }
          }

          // Question section ------------------------------------------------------------------------------------->>>
          .question_sec {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 18px;

            h5 {
              color: #04103b;
              font-family: IRANSans(FaNum);
              font-size: 21.312px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            // Comment Box section ------------------------------------------------------------------------------------->>>
            .comment_box {
              margin-bottom: 15px;

              textarea {
                border: 1.332px solid #ebe8ff;
                background: #fff;
                border-radius: 8px;
                padding: 17px 25px;
              }

              .comment_box_heading {
                color: #444;
                font-family: IRANSansX;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 150% */
              }
              .comment_box_content {
                color: #797d8c;
                font-family: "IRANSansX";
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
              }

              .comment_box_btn {
                display: flex;
                justify-content: end;
                padding-top: 15px;
              }
            }

            // answer section ------------------------------------------------------------------------------------->>>
            .answers_box {
              .answer_box {
                margin-bottom: 16px;

                p {
                  color: #797d8c;
                }

                .answer_top {
                  display: flex;
                  align-items: center;
                  gap: 10px;

                  .answer-img {
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;

                    img {
                      border-radius: 50%;
                    }
                  }
                }

                .answer_content {
                  padding-top: 8px;
                }

                .answer_bottom {
                  display: flex;
                  gap: 13px;

                  .bottom_icons {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                  }
                }
              }
            }
          }

          // Related question section ------------------------------------------------------------------------------------->>>
          .related_question_sec {
            width: 70%;

            // Related question heading ------------------------------------------------------------------------------------->>>
            .related_question_heading {
              padding-top: 13px;
              padding-bottom: 7px;
              color: #04103b;
              font-family: "IRANSansX";
              font-size: 21.312px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              h5 {
              }
            }

            // Related question boxes section ------------------------------------------------------------------------------------->>>
            .related_questions_boxes {
              display: flex;
              gap: 20px;
            }
          }
        }
      }
    }

    .UserLevel_Content {
      .user_level {
        margin-top: 95px;
        padding: 103px 12px 98px 0px;
        display: flex;
        width: 100%;
        max-height: 95vh;
        overflow-y: auto;

        .achievements {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          width: 40%;
          height: 100%;

          .profile {
            margin-bottom: 25px;
            display: flex;
            flex-direction: column;
            gap: 43px;
            justify-content: center;
            align-items: center;

            .profile_img {
              width: 180px;
              height: 180px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;

              img {
                width: 90%;
                height: 90%;
                object-fit: contain;
              }
            }

            h1 {
              color: #6c6c6c;
              font-family: "IRANSansX", sans-serif;
              font-size: 30px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .user_data {
              text-align: center;

              h2 {
                color: #000;
                font-family: "IRANSansX", sans-serif;
                font-size: 28px;
                font-weight: 400;
              }

              h3 {
                color: #6c6c6c;
                font-family: "IRANSansX", sans-serif;
                font-size: 24px;
                font-weight: 400;
              }
            }
          }

          .levels {
            background-color: #fff;
            max-width: 408px;
            max-height: 135px;
            border-radius: 25px;
            fill: #fff;
            filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.2));
            padding: 18px 25px;

            .level_points {
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              gap: 15px;

              .level_heading {
                h1 {
                  color: #000;
                  font-family: Inter;
                  font-size: 21.056px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 27.248px; /* 129.412% */
                  letter-spacing: -0.372px;
                }

                span {
                  color: rgba(0, 0, 0, 0.3);
                  font-feature-settings: "clig" off, "liga" off;
                  font-family: Inter;
                  font-size: 14.863px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  letter-spacing: 0.025px;
                }
              }
            }
          }

          .medals {
            display: flex;
            flex-direction: column;

            h5 {
              color: var(--color-grey, #9098a3);
              font-size: 14.863px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: 0.025px;
              margin: 10px;

              span {
                color: rgba(144, 152, 163, 0.5);
                font-size: 14.863px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.025px;
              }
            }

            .medal_cards {
              gap: 40px;
              display: flex;

              .medal_card {
                background-color: #fff;
                width: 125.095px;
                height: 131.287px;
                border-radius: 24.771px;
                padding: 18px;
                fill: #fff;
                filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.2));
                display: flex;
                flex-direction: column;
                align-items: center;

                .icon {
                  width: 40.68px;
                  height: 40.68px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                h1 {
                  color: #000;
                  text-align: center;
                  font-feature-settings: "clig" off, "liga" off;
                  font-family: Inter;
                  font-size: 14.863px;
                  font-weight: 600;
                  letter-spacing: 0.025px;
                }

                .gold_btn {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  width: 34.68px;
                  height: 23.533px;
                  border-radius: 7.431px;
                  background: #fcf3de;
                  color: #edb552;
                  text-align: center;
                  font-family: "Inter", sans-serif;
                  font-size: 14.863px;
                  font-weight: 600;
                  letter-spacing: 0.025px;
                  border: none;
                  outline: none;
                }

                .silver_btn {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 7.431px;
                  width: 34.68px;
                  height: 23.533px;
                  background: #eceef1;
                  color: #717484;
                  text-align: center;
                  font-family: "Inter", sans-serif;
                  font-size: 14.863px;
                  font-weight: 600;
                  letter-spacing: 0.025px;
                  border: none;
                  outline: none;
                }

                .bronze_btn {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  border-radius: 7.431px;
                  width: 34.68px;
                  height: 23.533px;
                  background-color: #f2eeec;
                  border: none;
                  outline: none;
                  color: #aa9083;
                  text-align: center;
                  font-family: Inter;
                  font-size: 14.863px;
                  font-weight: 600;
                  letter-spacing: 0.025px;
                }
              }
            }
          }
        }

        .center_line_img {
          height: 100%;
        }

        .activity_and_certification {
          display: flex;
          flex-direction: column;
          width: 60%;
          padding-left: 40px !important;
          padding-right: 10px;
          height: 100%;

          h4 {
            color: var(--color-grey, #9098a3);
            font-size: 14.863px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 15px;
          }

          .activity {
            display: flex;
            gap: 16px;
            margin-bottom: 26px;
            max-width: 420px;
            flex-wrap: wrap;

            .activity_card {
              display: flex;
              align-items: center;
              gap: 19px;
              width: 198.17px;
              height: 84.222px;
              padding: 24px 21px;
              border-radius: 24.771px;
              fill: #fff;
              filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
              background: #fff;

              .points {
                h1 {
                  color: #000;
                  font-family: Inter;
                  font-size: 21.056px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 27.248px; /* 129.412% */
                }

                span {
                  color: var(--color-grey, #9098a3);
                  font-family: Inter;
                  font-size: 14.863px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  letter-spacing: 0.025px;
                }
              }
            }
          }

          .strongest {
            display: flex;
            width: 100%;
            margin-bottom: 44px;
            gap: 22px;

            .strongest_card_wrapper {
              display: flex;
              flex-direction: column;
              gap: 15px;
              width: 50%;

              h3 {
                color: var(--color-grey, #9098a3);
                font-family: Inter;
                font-size: 14.863px;
                font-weight: 600;
              }

              .strongest_card {
                width: 100%;
                border-radius: 24.771px;
                fill: #fff;
                filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.1));
                background: #fff;
                gap: 25px;
                padding: 30px 40px 30px 15px !important;
                display: flex;
                flex-direction: column;
                .strongest_box {
                  display: flex;
                  gap: 15px;

                  .strong_img {
                    img {
                      width: 50.542px;
                      height: 40.634px;
                    }
                  }

                  .heading {
                    display: flex;
                    gap: 10px;
                    flex-direction: column;

                    h1 {
                      color: var(--base-shade-100, #000);
                      // font-feature-settings: "clig" off, "liga" off;
                      font-family: Inter;
                      font-size: 14.863px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: normal;
                      letter-spacing: 0.025px;
                    }
                    .progress {
                      width: 130px;
                    }

                    .back_end_btn {
                      display: flex;
                      gap: 7px;
                      .back_end_btn_img {
                        width: 183px;
                        height: 10px;
                        img {
                          width: 100%;
                          height: 100%;
                        }
                      }

                      h2 {
                        // background-color: red;

                        color: var(--base-shade-70, rgba(0, 0, 0, 0.7));
                        text-align: right;
                        font-feature-settings: "clig" off, "liga" off;
                        font-family: Inter;
                        font-size: 14.863px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 0.025px;
                      }

                      h3 {
                        color: var(--base-shade-30, rgba(0, 0, 0, 0.3));
                        font-feature-settings: "clig" off, "liga" off;
                        font-family: Inter;
                        font-size: 14.863px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 0.025px;
                      }
                    }
                  }
                }
              }
            }
          }

          .certification {
            width: 100%;
            display: flex;
            gap: 40px;

            .medal_card {
              background-color: #fff;
              width: 170.921px;
              height: 189.5px;
              border-radius: 24.771px;
              padding: 23px 18px;
              fill: #fff;
              filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.1));
              display: flex;
              flex-direction: column;
              align-items: center;

              .icon {
                width: 60.68px;
                height: 60.68px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              h1 {
                color: #000;
                text-align: center;
                font-size: 14.863px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.025px;
                margin-bottom: 30px;
              }

              .gold_btn {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 132.526px;
                height: 27.248px;
                border-radius: 7.431px;
                // background: #edb552;
                // opacity: 0.15;
                background: #f6f4ee;
                color: #a59e8e;
                text-align: center;
                font-family: Inter;
                font-size: 14.863px;
                font-weight: 600;
                letter-spacing: 0.025px;
                border: none;
                outline: none;
              }

              .silver_btn {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 7.431px;
                // opacity: 0.15;
                width: 132.526px;
                height: 27.248px;
                background: #e7e7e7;
                color: #778390;
                text-align: center;
                // font-feature-settings: "clig" off, "liga" off;
                font-family: Inter;
                font-size: 14.863px;
                font-weight: 600;
                letter-spacing: 0.025px;
                border: none;
                outline: none;
              }

              .bronze_btn {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                border-radius: 7.431px;
                width: 132.526px;
                height: 27.248px;
                background: #f6f4ee;
                border: none;
                outline: none;

                color: #a59e8e;
                text-align: center;
                // font-feature-settings: "clig" off, "liga" off;
                font-family: Inter;
                font-size: 14.863px;
                font-weight: 600;
                letter-spacing: 0.025px;
              }
            }
          }
        }
      }
    }

    .UserProfile_Content {
      // Profile content section ------------------------------------------------------------------------- >>>
      .profile_content {
        display: flex;
        align-items: center;
        gap: 50px;
        padding: 140px 50px 40px;
        height: 100vh;
        overflow-y: auto;
        margin-top: 95px;

        .profile_section {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 30px;

          &::after {
            content: "";
            height: 110%;
            width: 1px;
            position: absolute;
            right: -97px;
            top: -25px;
            background-color: #e6e6e6;
          }

          .user_prof_heading {
            color: #6c6c6c;
            font-family: "IRANSansX", sans-serif;
            font-size: 30px;
            text-align: center;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .profile-image-container {
            position: relative;
            display: inline-block;
          }

          .profile_img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            object-fit: cover;
            position: relative;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              cursor: pointer;
              border: 1px solid gray;
            }

            .ProfileIcon {
              position: absolute;
              top: 80%;
              left: 90%;
              transform: translate(-50%, -50%);
              cursor: pointer;
            }
          }

          .name_desig {
            text-align: center;

            h6 {
              color: #000;
              font-family: "IRANSansX", sans-serif;
              font-size: 28px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            p {
              color: #6c6c6c;
              font-family: "IRANSansX", sans-serif;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .details_sec {
            display: flex;
            gap: 100px;

            .position {
              position: relative;

              &::after {
                content: "";
                height: 60%;
                width: 1px;
                position: absolute;
                right: -42px;
                top: 15px;
                background-color: #6c6c6c;
              }
            }

            .points {
              position: relative;
            }

            h6 {
              color: #000;
              font-family: "IRANSansX", sans-serif;
              font-size: 33.011px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-align: center;
              display: flex;
              align-items: center;
              gap: 15px;
              span {
                color: #6c6c6c;
                text-align: center;
                font-family: "IRANSansX", sans-serif;
                font-size: 19.505px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }

          .user_prof_footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 13px;

            Button {
              color: #fff;
              font-family: "IRANSansX", sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              border-radius: 10px;
            }
            h6 {
              color: #6c6c6c;
              font-family: "IRANSansX", sans-serif;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .user-bio {
              width: 391px;
              p {
                color: #6c6c6c;
                text-align: center;
                font-family: "IRANSansX", sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                word-break: break-all;
                // width: 400px !important;

                // overflow: scroll;
              }
            }
          }
        }

        .profile_form_section {
          width: 100%;
          padding: 0px 265px 0px 130px;

          hr {
            color: #bcbcbc;
          }

          .basic_info {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 20px;
            h6 {
              padding-bottom: 10px;
              color: #000;
              font-family: IRANSans(FaNum);
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
            .basic_info_heading {
              color: #000;
              text-transform: uppercase;
              font-family: IRANSans(FaNum);
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .basic_info_btn {
              display: flex;
              gap: 10px;
            }
          }

          form {
            width: 100%;

            .input_flex {
              display: flex;
              flex-direction: column;
              gap: 5px;
              margin-bottom: 25px;

              input {
                padding: 5px 10px;
                border-radius: 4px;
                border: 2px solid #e5e5e5;
              }
            }

            .error {
              font-size: 15px;
              color: red;
            }

            label {
              color: #000;
              font-family: IRANSans(FaNum);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .first_last {
              padding-top: 34px;
              width: 100%;
              display: flex;
              gap: 20px;

              .firstname {
                width: 50%;
              }

              .lastname {
                width: 50%;
              }
            }

            .about_me {
              padding-bottom: 30px;

              h6 {
                padding-bottom: 10px;
                color: #000;
                font-family: IRANSans(FaNum);
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }

            .about_me_input {
              width: 100%;

              textarea {
                padding: 10px;
                width: 100%;
                resize: none;
                // color: #bbb;
                // font-family: Poppins;
                // font-size: 14px;
                // font-style: normal;
                // font-weight: 400;
                line-height: normal;
                border-radius: 4px;
                border: 2px solid #e5e5e5;
              }
            }

            .logout_btn {
              width: 100%;
              display: flex;
              justify-content: end;
              color: #fff;
              font-family: IRANSans(FaNum);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }
      .logout_button {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
      }
    }

    .ContactUs_Content {
      .contact_us_section {
        padding: 80px 123px;
        display: flex;
        gap: 20px;
        justify-content: center;
        height: 90vh;
        overflow-y: auto;
        margin-top: 95px;
        .contact_us_form {
          width: 50%;
          border-radius: 8px;
          height: fit-content;
          background: #fff;
          padding: 50px;
          box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.3);

          form {
            // height: 100%;
            h1 {
              color: #000;
              margin-bottom: 94px;
              text-align: right;
              font-family: "Volkhov", sans-serif;
              font-size: 36px;
              font-style: normal;
              font-weight: 400;
              text-align: center;
            }

            .inputs {
              input {
                width: 100%;
                padding: 20px;
                margin-bottom: 3px !important;
                border-radius: 4px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                background: #f6f6f6;
              }
              .error-msg {
                color: red;
                font-weight: normal;
                padding-bottom: 10px;
              }
              textarea {
                width: 100%;
                padding: 10px;
                margin-bottom: 3px !important;
                border-radius: 4px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                background: #f6f6f6;
              }

              p {
                color: #000;
                font-family: "IRANSansX";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
            }

            .submit_btn {
              width: 100%;
              padding: 20px;
              margin-top: 60px;
              border-radius: 4px;
              border: 2px solid $primary;
              background: #f6f6f6;
              transition: 600ms ease;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
            }

            .submit_btn:hover {
              transition: 600ms ease;
              background-color: $primary;
              color: #fff;
            }
          }
        }
        .contact_us_img {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          img {
            width: 100%;
          }
        }
      }

      .support_section {
        padding: 60px 110px;
        height: 100%;
        overflow-y: auto;
        margin-top: 95px;
        .support_heading {
          text-align: center;
          display: flex;
          gap: 10px;
          flex-direction: column;
          padding: 0 140px;
          h1 {
            font-family: Volkhov;
            font-size: 64px;
            font-style: normal;
            font-weight: 400;
          }
          p {
            font-family: IRANSansX;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }
        }
        .cards_heading {
          h1 {
            font-family: Volkhov;
            font-size: 32px;
            font-style: normal;
            margin-top: 16px;
            font-weight: 400;
            margin-bottom: 20px;
          }
        }
        .support_cards {
          display: grid;
          grid-template-columns: auto auto;
          // flex-wrap: wrap;
          gap: 27px;
        }
      }
    }

    .Report_Bugs {
      .contact_us_section {
        padding: 40px 123px 20px 123px;
        display: flex;
        gap: 20px;
        justify-content: center;

        margin-top: 95px;
        .contact_us_form {
          width: 50%;
          border-radius: 8px;
          height: fit-content;
          background: #fff;
          padding: 40px;
          box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.3);

          form {
            // height: 100%;
            h1 {
              color: #000;
              margin-bottom: 60px;
              text-align: right;
              font-family: "Volkhov", sans-serif;
              font-size: 36px;
              font-style: normal;
              font-weight: 400;
              text-align: center;
            }

            .inputs {
              input {
                width: 100%;
                padding: 15px 20px;
                margin-bottom: 3px !important;
                border-radius: 4px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                background: #f6f6f6;
              }

              textarea {
                width: 100%;
                padding: 20px;
                margin-bottom: 3px !important;
                border-radius: 4px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                background: #f6f6f6;
              }

              p {
                color: #000;
                font-family: "IRANSansX";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
            }

            .submit_btn {
              width: 100%;
              padding: 20px;
              margin-top: 60px;
              border-radius: 4px;
              border: 2px solid $primary;
              background: #f6f6f6;
              transition: 600ms ease;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
            }

            .submit_btn:hover {
              transition: 600ms ease;
              background-color: $primary;
              color: #fff;
            }
          }
        }
        // .contact_us_img {
        //   width: 50%;
        //   display: flex;
        //   flex-direction: column;
        //   justify-content: center;
        //   img {
        //     width: 100%;
        //   }
        // }
      }
    }
  }
}

.view_hint_modal {
  .modal-content {
    width: 808px;
    height: 650.165px;

    background-color: transparentize($color: rgba(0, 0, 0, 0.5), $amount: 0);
    padding-bottom: 5px;
  }

  .modal-body {
    .modal_question {
      padding: 33px 28px;
      background: #f9f9f9;
    }
    .answer_code {
      border: 2px solid #f9f9f9;
      color: #f9f9f9;
      padding: 40px 110px;
      margin-top: 8px;

      // margin-bottom: 8px;
      height: 85%;
    }
  }
}

.source_modal {
  .modal-content {
    background-color: #40444b;
  }
  .modal-header {
    // margin-bottom: 0;
    border: none;
    color: white;
    // font-size: 32px;
    // text-align: center;
    .modal-title {
      font-size: 27px;
    }
  }
  .modal-body {
    padding-top: 0;
    h3 {
      color: white;
    }
    .dropdown {
      margin-top: 20px;
      select {
        margin-bottom: 10px;
        width: 100%;
        appearance: none;
        padding: 6px 15px;
        border-radius: 5px;
        border: 1px solid #202225;
        background: #fff;
        background-image: url("../../../assets/icons/drop-down.png");
        background-repeat: no-repeat;
        background-position: calc(100% - 13px) center;
        background-size: 13px 13px;
        box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.15);
        // color: #d6d5d5;
        font-family: IRANSansX;
        font-size: 16px;
        font-weight: 300;

        &:focus {
          // border-color: #202225; /* Set the border color to blue when focused */
          // border-width: 1px;
          outline: #202225; /* Remove the default focus outline */
          outline-width: 1px;
          outline-style: solid;
          // border: none;
        }

        &::-ms-expand {
          display: none;
        }

        option:hover {
          background-color: #000;
        }
      }

      .associate_table {
        margin-bottom: 10px;
        width: 50%;
        appearance: none;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #202225;
        background: #fff;
        background-image: url("../../../assets/icons/drop-down.png");
        background-repeat: no-repeat;
        background-position: calc(100% - 23px) center;
        background-size: 23px 23px;
        box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.15);
        // color: #d6d5d5;
        font-family: IRANSansX;
        font-size: 16px;
        font-weight: 300;

        &:focus {
          // border-color: #202225; /* Set the border color to blue when focused */
          // border-width: 1px;
          outline: #202225; /* Remove the default focus outline */
          outline-width: 1px;
          outline-style: solid;
          // border: none;
        }

        &::-ms-expand {
          display: none;
        }
      }

      option {
        // color: #d6d5d5;
        font-family: IRANSansX;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }

    button {
      border: none;
      padding: 6px 15px;
      margin-top: 10px;
      background-color: #25292f;
      color: white;
      font-size: 14px;
      transition-property: width;
      transition-duration: 1s;
      // border-radius: 4px;
      width: 102px;
    }

    button:hover {
      background-color: #f72e52;
      // font-size: 14px;
      width: 115px;
    }
  }

  .modal-footer {
    border: none;
  }
}

.level_modal {
  .modal-content {
    background-color: transparentize($color: rgba(0, 0, 0, 0.5), $amount: 0);
    width: 564px;
    height: 230px;

    // width: 564px !important;
    // height: 250px;
    .modal-body {
      .levels {
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: center;
        align-items: center;

        justify-content: center;

        .soft {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          width: 517px;
          height: 59px;
          background: #ffe600;
          color: #fff;
        }

        .easy {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 517px;
          height: 59px;
          border-radius: 4px;
          background: #07c290;
          color: white;
        }

        .hard {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 517px;
          height: 59px;
          border-radius: 4px;
          background: #ee2a59;
          color: white;
        }
      }
    }
  }
}

// <---------------- Components Style ---------------->

.topbar {
  .top {
    width: calc(100% - 320px);
    // border: 1px solid red;
    display: flex;
    justify-content: space-between;
    padding: 20px 25px 20px 46px !important;
    // position: relative;
    // z-index: 9;
    gap: 10px;
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 100;

    .search_section {
      width: 74%;
      display: flex;
      align-items: center;
      gap: 130px;

      .side_menu_icon {
        display: none;
      }
      .collapse {
        transform: translateX(0%);
      }

      h1 {
        width: 300px;
        // background-color: #07c290;
        color: #1f192f;
        font-family: IRANSans(FaNum);
        font-size: 42.624px;
        font-weight: 700;
      }

      .search_bar {
        background-color: #f8f8f8;
        padding: 10px 30px;
        border-radius: 50px;
        // min-width: 550px;
        min-width: 43%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 53px;

        input {
          background: transparent;
          border: none;
          outline: none;
          margin-bottom: unset;
          width: 100%;
          padding-right: 20px;
        }

        img {
          cursor: pointer;
        }
      }
      .hidebar {
        display: none;
      }
    }

    .profile_section {
      display: flex;
      gap: 30px;
      align-items: center;
      position: relative;

      .search_icon {
        display: none;
      }

      img {
        cursor: pointer;
      }

      .notification_section {
        position: relative;

        .notifications {
          min-width: 180px;
          position: absolute;
          top: 30px;
          right: 0;
          box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
          padding: 10px;
          border-radius: 10px;
          background-color: white;
        }
      }

      .profile {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        position: relative;

        .profile_img {
          height: 50px;
          width: 50px;
          border-radius: 50%;
        }

        span {
          color: #7b7b7b;
          user-select: none;
          // width: 150px;
          font-size: 18px;
        }
      }

      .profile_modal {
        position: absolute;
        padding: 20px;
        border-radius: 10px;
        top: 50px;
        right: 0px;
        box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
        max-width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        gap: 10px;

        p {
          font-weight: 700;
          font-size: 18px;
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    display: none !important;

    // transform: translateY(-100%);
    // z-index: -1 !important;
    .search_bar {
      // display: none !important;
      background-color: #f8f8f8;
      padding: 10px 30px;
      border-radius: 50px;
      width: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 53px;

      input {
        background: transparent;
        border: none;
        outline: none;
        margin-bottom: unset;
        width: 100%;
        padding-right: 20px;
      }

      img {
        cursor: pointer;
      }
    }
  }
}

.db_card {
  width: 189px;
  height: 118px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
  border: 2px solid transparent;
  cursor: pointer;
  transition: 400ms ease-out;
  h1 {
    color: #1f192f;
    text-align: center;
    transition: 400ms ease-out;
    font-family: IRANSans(FaNum);
    font-size: 20px;
    font-weight: 500;
  }
}

.db_card:hover {
  border: 2px solid $primary;
  transition: 400ms ease-in-out;
  h1 {
    transition: 400ms ease-in-out;
    color: $primary;
  }
}

.history_card {
  border-radius: 8px;
  border: 1px solid $primary;
  background: #f6f6f6;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  .question {
    display: flex;
    gap: 10px;
    h1 {
      font-family: "IRANSans(FaNum)", sans-serif;
      font-size: 25px;
      font-weight: 600;
      // width: 30px;
    }

    span {
      font-family: "IRANSansX", sans-serif;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .answer {
    width: 100%;
    display: flex;
    gap: 10px;

    h1 {
      font-family: "IRANSans(FaNum)", sans-serif;
      font-size: 25px;
      font-weight: 600;
    }

    span {
      display: block;
      font-family: "IRANSansX", sans-serif;
      color: #797d8c;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px; /* 150% */

      span {
        color: #444;
        font-family: "IRANSansX", sans-serif;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

.custom_btn {
  background-color: white;
  border: 1px solid $primary;
  outline: none;
  border-radius: 5px;
  padding: 5px 12px;
  color: $primary;
  min-width: 96px;
  font-size: 16px;
  font-weight: 500;
}

.gray_button {
  background-color: white;
  outline: none;
  padding: 0px 6px;
  // min-width: 80px;
  width: 179px !important;
  height: 38px !important;
  border-radius: 4px;
  border: 1px solid #909090;
  color: #909090;
  font-family: "IRANSansX", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.custom_btn_active {
  background-color: $primary;
  color: white;
  border-radius: 5px;
  padding: 5px 12px;
  border: none;
  min-width: 90px;
  font-size: 16px;
  font-weight: 500;
  // margin-top: 15px;
}

.logout_btn {
  // width: 100%;

  background-color: $primary;
  color: white;
  border-radius: 5px;
  padding: 5px 12px;
  border: none;
  min-width: 143px;
  min-height: 36px;
  font-size: 16px;
  font-weight: 500;
  // background-color: $primary;
  // color: white;
  // border-radius: 5px;
  // padding: 5px 12px;
  // border: none;
  // min-width: 143px;
  // min-height: 36px;
  // font-size: 16px;
  // font-weight: 500;
  // cursor: pointer;
  // width: 100%;
}

.hint_btn {
  background-color: #ffe4a1;
  border: 1px solid #ffb800;
  outline: none;
  border-radius: 5px;
  padding: 5px 12px;
  color: #444444;
  min-width: 80px;
  font-size: 16px;
  font-weight: 500;
}

.support_card {
  border-radius: 7.335px;
  background: #f6f6f6;
  max-width: 642.747px;
  padding: 15px 30px;

  h1 {
    font-family: Volkhov;
    font-size: 18.338px;
    font-style: normal;
    font-weight: 400;
  }

  p {
    font-family: IRANSansX;
    font-size: 12.837px;
    font-style: normal;
    font-weight: 400;
  }

  a {
    color: black;
    font-family: IRANSansX;
    font-size: 12.837px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
  }
}

.discussion_card {
  // max-width: 458.766px;
  padding: 17px 25px 0 26px;

  border-radius: 8px;
  border: 1.332px solid #ebe8ff;
  background: #fff;

  h2 {
    font-family: "IRANSansX", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  p {
    color: #797d8c;
    // background-color: black;
    font-family: IRANSansX;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    // line-height: 24px;
    margin: 0;
  }
}

.winner_card {
  border-radius: 30px;
  background: #f6f6f6;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .name {
    display: flex;

    span {
      color: #909090;
      font-family: "IRANSansX", sans-serif;
      font-size: 20px;
      font-style: normal;
      margin-right: 15px;
      font-weight: 500;
      line-height: 24px; /* 120% */
    }
  }

  .icons {
    span {
      color: #909090;
      font-family: "IRANSansX", sans-serif;
      font-size: 20px;
      font-style: normal;
      margin: 0 15px;
      font-weight: 500;
      line-height: 24px; /* 120% */
    }
  }
}

// Custom Modal styling start ---------------------------------------------------------------------------------------->>>
.custom_modal_head {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  h4 {
    color: #000;
    font-family: "IRANSansX", sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 104.348% */
  }
}

.modal_body_flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom_modal_body {
  width: 85%;

  p {
    align-self: flex-start;
    color: #000;
    font-family: "IRANSansX", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-bottom: 5px;
  }

  .modal_input_box_show {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    input {
      padding: 5px 10px;
      width: 100%;
      border-radius: 4px;
      border: 1px solid #cbcaca;
      font-family: "IRANSansX", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      &::placeholder {
        color: #d0d0d0;
      }
    }
  }

  .modal_input_box_hide {
    display: none;
  }

  textarea {
    border-radius: 4px;
    border: 1px solid #cbcaca;
    font-family: "IRANSansX", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    padding: 10px;
    width: 100%;
    resize: none;

    &::placeholder {
      color: #d0d0d0;
    }
  }
}

.custom_modal_btn {
  width: 85%;
  display: flex;
  justify-content: end;
  gap: 15px;
  margin-top: 10px;
  padding-bottom: 40px;

  Button {
    font-family: "IRANSansX", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
// Custom Modal styling end ---------------------------------------------------------------------------------------->>>

// <----------------- Media Query --------------->

@media screen and (max-width: 1800px) {
  .User_Dashboard {
    display: flex;

    .topbar {
      .top {
        width: calc(100% - 300px);
        .search_section {
          gap: 20px;

          .search_bar {
            // min-width: 500px;
          }
        }
      }
    }

    // .topbar {
    //   .profile_section {
    //     .profile {
    //       .profile_img {
    //         width: 40px;
    //         height: 40px;
    //       }
    //     }
    //   }
    // }

    .Sidebar {
      width: 300px;
      .logo {
        // margin-bottom: 90px;
      }
      .buttons {
        gap: 10px;
        .container {
          padding-left: 35px !important;

          .btn {
            font-size: 16px;
          }
        }

        .active_container {
          padding-left: 35px;

          .active_btn {
            font-size: 16px;
          }
        }
      }

      .progress_bar {
        img:first-child {
          width: 90px;
          height: 120px;
        }

        img:last-child {
          // width: 90%;
        }

        .your_progress {
          input {
            // width: 270px;
          }
        }
      }
    }

    .Content {
      // width: 100%;
      width: calc(100% - 300px);
      margin-left: 300px;
      .AI_Content {
      }

      .Database_Content {
      }

      .Dashboard_Content {
      }

      .History_Content {
      }

      .LeaderBoard_Content {
        .leader_board {
          .winning_section {
            .cone_left {
              // width: 300px;
            }

            .cone_right {
              // width: 350px;
            }
            .winner_one {
            }

            .winner_two {
            }

            .winner_three {
            }
          }
        }
      }

      .Subscription_Content {
      }

      .DiscussionForm_Content {
      }

      .UserLevel_Content {
        .user_level {
          .activity_and_certification {
            padding-left: 20px;

            .strongest {
              flex-wrap: wrap;
              .strongest_card_wrapper {
                .strongest_card {
                  // padding: 20px 16px;

                  .strongest_box {
                    // width: 100%;
                    .heading {
                      .back_end_btn {
                        .back_end_btn_img {
                          margin-right: 5px;
                          width: 120px !important;
                          height: 10px !important;
                        }
                      }
                    }
                  }
                }
              }
              gap: 15px;
            }
          }
        }
      }

      .UserProfile_Content {
        .profile_form_section {
          padding: 0px 50px 0px 100px !important;
        }
      }

      .ContactUs_Content {
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .User_Dashboard {
    display: flex;

    .Sidebar {
      width: 260px;
      .logo {
        // margin-bottom: 80px;
      }
      .buttons {
        .container {
          padding-left: 30px !important;

          .btn {
            font-size: 14px;
          }
        }

        .active_container {
          padding-left: 30px !important;

          .active_btn {
            font-size: 14px;
          }
        }
      }

      .progress_bar {
        .your_progress {
          input {
            // width: 205px;
          }
        }
      }
    }

    .Content {
      // padding: 20px 40px;
      // width: 100%;
      margin-left: 260px;
      width: calc(100% - 260px);

      .AI_Content {
      }

      .Database_Content {
        .database_section {
          .db_cards {
            gap: 25px;
            justify-content: center !important;
            align-items: center;
          }
        }
      }

      .Dashboard_Content {
      }

      .History_Content {
        .history_section {
          // background-color: #07c290;
          // width: 100%;
        }
      }

      .LeaderBoard_Content {
        .leader_board {
          // background-color: #07c290;
          .winning_section {
            // width: 100%;
            .cone_left {
              // width: 250px;
            }

            .cone_right {
              // width: 280px;
            }
            // height: 350px;
            .winner_one {
              .winner_img {
                img {
                }
              }

              .winner_name {
              }

              .winner_points {
                font-size: 12px;
              }
            }

            .winner_two {
              // top: 17%;
              // left: 31% !important;
              .winner_img {
                img {
                }
              }
            }
            .winner_three {
              // left: 60% !important;

              .winner_img {
                img {
                }
              }
            }
          }
        }
      }

      .Subscription_Content {
        .card_section {
          .heading {
            h1 {
              font-size: 38px;
              font-weight: 500;

              span {
                font-size: 38px;
                font-weight: 500;
              }
            }
          }

          .cards {
            flex-wrap: wrap;

            .subscription_card {
              // width: 30%;
              padding: 30px 0 100px 0;
              // margin: 0 !important;

              .top_card {
                gap: 20px;

                .card_heading {
                  h1 {
                    font-size: 24px;
                  }
                }

                .price_and_buttons {
                  .price {
                    h1 {
                      font-size: 25px;
                    }
                  }
                }
              }

              .card_content {
                margin-top: 30px;
                gap: 15px;
              }
            }
          }

          .question {
            // width: 30%;
            width: 40%;
            margin-top: -60px;
          }
        }
      }

      .DiscussionForm_Content {
        .discussion_community {
          .discussion_cards {
            grid-template-columns: auto auto;
          }
        }
      }

      .UserLevel_Content {
        .user_level {
          padding: 30px 10px 50px 0;
          .achievements {
            padding: 0 30px;

            .profile {
              h1 {
                font-size: 22px;
              }
            }
            .levels {
              // width: 300px;
              width: 100%;

              .level_btn {
                width: 100%;

                img {
                  width: 100%;
                }
              }
            }
            .medals {
              .medal_cards {
                flex-wrap: wrap;
                align-items: center;
                justify-content: stretch;
                gap: 20px;
              }
            }
          }

          .activity_and_certification {
            .strongest {
              flex-wrap: wrap;
              flex-direction: column;

              .strongest_card_wrapper {
                width: 74%;

                .strongest_card {
                  width: 100%;
                  .strongest_box {
                    .heading {
                      .back_end_btn {
                        .back_end_btn_img {
                          margin-right: 5px;
                          width: 120px !important;
                          height: 10px !important;
                        }
                      }
                    }
                  }
                }
              }
            }

            .certification {
              flex-wrap: wrap;
              justify-content: stretch;
              gap: 20px;
              justify-content: left;

              .medal_card {
                width: 150px !important;
                height: 150px !important;
                padding: 10px !important;

                .gold_btn {
                  height: 20px !important;
                  font-size: 10px !important;
                }
                .silver_btn {
                  height: 20px !important;
                  font-size: 10px !important;
                }
                .bronze_btn {
                  height: 20px !important;
                  font-size: 10px !important;
                }
                h1 {
                  font-size: 15px !important;
                }
              }
            }
          }
        }
      }

      .UserProfile_Content {
        .profile_content {
          width: 100%;

          .profile_section {
            width: 100%;

            &::after {
              height: 90%;
              width: 1px;
              right: -27px;
            }
          }
        }

        .profile_form_section {
          width: 100%;
          padding: 20px !important;

          form {
            width: 100%;
          }
        }
      }

      .ContactUs_Content {
        .contact_us_section {
          padding: 20px 50px;

          .contact_us_form {
            padding: 40px;
            // height: 80vh;

            form {
              h1 {
                margin-bottom: 40px;
              }

              .inputs {
                input {
                  padding: 10px;
                  margin-bottom: 3px;
                  border-radius: 3px;
                }

                p:last-child {
                  margin-top: 0 !important;
                  font-weight: 300;
                }
              }

              .submit_btn {
                padding: 10px;
                margin-bottom: 20px;
                margin-top: 20px;
                border-radius: 3px;
              }
            }
          }
        }

        .support_section {
          // padding: 40px 180px;
          padding: 0px 60px;
          .support_heading {
            h1 {
              font-size: 40px;
            }
          }

          .cards_heading {
            h1 {
              font-size: 24px;
            }
          }

          .support_cards {
            // flex-wrap: wrap;
          }
        }
      }

      .Report_Bugs {
        .contact_us_section {
          padding: 20px 50px;

          .contact_us_form {
            padding: 40px;
            // height: 80vh;

            form {
              h1 {
                margin-bottom: 40px;
              }

              .inputs {
                input {
                  padding: 10px;
                  margin-bottom: 20px;
                  border-radius: 3px;
                }

                p:last-child {
                  margin-top: 0 !important;
                  font-weight: 300;
                }
              }

              .submit_btn {
                padding: 10px;
                margin-bottom: 20px;
                margin-top: 20px;
                border-radius: 3px;
              }
            }
          }
        }
      }
    }
  }

  .support_card {
    max-width: 550px;
    // background-color: green;
  }

  // <---------------- Components Style ---------------->
  .topbar {
    .top {
      width: calc(100% - 260px) !important;
      padding: 20px 30px !important;
      .search_section {
        gap: 20px !important;

        .side_menu_icon {
          display: noe;
        }

        h1 {
          width: 250px;
          font-size: 32px;
          // background-color: #07c290;
        }

        .search_bar {
          padding: 10px 30px;
          // min-width: 400px !important;

          input {
            width: 100%;
            padding-right: 20px;
          }

          img {
          }
        }
      }

      .profile_section {
        gap: 30px;

        img {
        }

        .notification_section {
          .notifications {
            min-width: 180px;
            padding: 10px;
          }
        }

        .profile {
          gap: 10px;

          .profile_img {
            height: 40px;
            width: 40px;
            border-radius: 50%;
          }

          span {
            width: 150px;
            font-size: 14px;
          }
        }

        .profile_modal {
          width: 200px;
          gap: 10px;

          p {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1220px) {
  .User_Dashboard {
    display: flex;

    .Sidebar {
      .progress_bar {
        .your_progress {
          input {
            // width: 200px;
          }
        }
      }
    }

    .Content {
      // padding: 20px 40px;
      // width: 100%;

      .AI_Content {
      }

      .Database_Content {
      }

      .Dashboard_Content {
      }

      .History_Content {
      }

      .LeaderBoard_Content {
        .leader_board {
          .winning_section {
            .winner_one {
              .winner_img {
              }

              .winner_name {
                font-size: 9px !important;
              }

              .winner_points {
                font-size: 10px !important;
              }
            }

            .winner_two {
              .winner_img {
              }
              .winner_name {
                font-size: 9px !important;
              }

              .winner_points {
                font-size: 10px !important;
              }
            }

            .winner_three {
              // left: 61% !important;
              .winner_img {
              }

              .winner_name {
                font-size: 9px !important;
              }

              .winner_points {
                font-size: 10px !important;
              }
            }

            .cone_left {
              // background-color: #07c290;
            }

            .cone_right {
            }
          }
        }
      }

      .Subscription_Content {
        .card_section {
          .cards {
            padding: 0 40px;
            flex-wrap: wrap;
            // background-color: #07c290 !important;
          }
        }
      }

      .DiscussionForm_Content {
      }

      .UserLevel_Content {
      }

      .UserProfile_Content {
      }

      .ContactUs_Content {
      }
    }
  }

  // <---------------- Components Style ---------------->
  .topbar {
    .top {
      // padding: ;
      .search_section {
        h1 {
          font-size: 29px;
        }
        .search_bar {
          // min-width: 350px !important;
          // background-color: #07c290;
          height: 43px;
        }
      }
      .profile_section {
        .search_section {
          .side_menu_icon {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 600px) {
  .db_card {
    width: 47%;
    height: 80px;
  }
  .db_card h1 {
    font-size: 17px !important;
  }

  .User_Dashboard {
    display: flex;

    .Sidebar {
      transform: translateX(-600px);
      width: 100%;
      transition: transform 0.5s ease;
      position: absolute;
      top: 80px;
      left: 0;
    }

    .Sidebar_show {
      position: absolute;
      top: 80px;
      left: 0;
      transform: translateX(0);
      transition: transform 0.5s ease;
      z-index: 20;
      width: 100%;

      .logo {
        .cross {
          // display: block;
          // padding-right: 20px;
        }
      }
    }

    .Content {
      width: 100%;
      margin-left: 0;
      .back-button {
        margin-left: 15px;
      }
      .no_question {
        font-size: 16px;
      }

      .AI_Content {
        .ai_section {
          flex-direction: column;
          align-items: center;
          padding: 20px;

          .instruct_section {
            width: 100%;

            textarea {
              padding: 10px;
            }

            h1 {
              font-size: 18px !important;
            }
          }

          .generate_section {
            width: 100%;

            .header {
              // align-items: center !important;
              // justify-content: center;
              flex-direction: column;
              align-items: flex-start;
              gap: 10px;
              h1 {
                font-size: 18px !important;
              }
              .btns {
                .submit_btn {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 160px !important;
                  height: 50px !important;
                }

                .view_hint_btn {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100px !important;
                  height: 50px !important;
                }
              }
            }

            .box {
              padding: 10px;
              h1 {
                font-size: 16px !important;
              }
            }
          }
        }
      }

      .Database_Content {
        .database_details {
          // padding: 20px 10px !important;
          .introduction {
            p {
              font-size: 12px !important;
              line-height: normal;
            }
            h1 {
              font-size: 16px !important;
            }
          }
          .description {
            p {
              font-size: 12px;
              line-height: normal;
            }
            h2 {
              font-size: 16px !important;
            }
          }
          max-height: 100%;
          padding: 20px 12px !important;
          .list_of_table {
            height: auto;

            .buttons {
              display: flex;
              justify-content: center;
            }
          }
        }
        .database_section {
          padding: 13px 20px !important;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .list_heading {
            h1 {
              font-size: 20px !important;
              margin-top: 0 !important;
            }
          }
          .db_cards {
            row-gap: 15px;
            column-gap: 12px;
            justify-content: center;
          }
        }
      }

      .Dashboard_Content {
        .dashboard_content {
          gap: 10px;
          flex-direction: column;

          padding: 0px 20px 20px;

          .question_section {
            width: 100%;

            .box {
              .half_width {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .blue_box {
                // padding: 10px 7px;
                .box_header {
                  padding: 10px;
                }
              }
              border: none;
            }

            h1 {
              font-size: 22px !important;
            }
          }

          .feedback_section {
            width: 100%;

            h1 {
              font-size: 22px !important;
            }

            .box {
              padding: 10px;
              // height: auto;
              min-height: auto !important;

              textarea {
                min-height: 200px;
              }
              h1 {
                font-size: 16px !important;
              }
            }

            .header {
              // flex-direction: column;
              align-items: flex-start;
              // flex-wrap: wrap;
              // gap: 10px;

              div {
                // background-color: #07c290;
                flex-wrap: wrap;
                justify-content: end;
              }
            }

            .result_header {
              flex-direction: column;
              gap: 20px;
              margin-bottom: 20px;

              h1 {
                font-size: 16px !important;
              }
            }
          }
        }
      }

      .History_Content {
        .history_section {
          padding: 20px;
          .recently_viewed_questions {
            .history_heading {
              h1 {
                font-size: 18px !important;
              }
            }
          }

          .saved_questions {
            .history_heading {
              h1 {
                font-size: 18px !important;
              }
            }
          }
        }

        .history_card {
          .question {
            gap: 6px;
            h1 {
              font-size: 20px !important;
            }

            span {
              font-size: 14px !important;
              // line-height: normal;
            }
          }

          .answer {
            gap: 6px;

            h1 {
              font-size: 20px !important;
            }

            span {
              font-size: 14px !important;
              // line-height: 22px;
              line-height: normal;
            }
          }
        }
      }

      .LeaderBoard_Content {
        .leaderboard_topbar {
          display: block;
        }
        .leader_board {
          margin-top: 80px;
          .winning_section {
            height: 300px;
            padding-top: 100px;
            .cone_left {
              width: 100px;
            }
            .cone_right {
              width: 120px;
            }

            .winners {
              // flex-direction: column;
              align-items: center;
              gap: 40px;
              .winner_one {
                .winner_img {
                }

                .winner_points {
                  font-size: 9px !important;
                }
                .winner_name {
                  font-size: 9px !important;
                }
              }
              .winner_three {
                .winner_points {
                  font-size: 10px !important;
                }
                .winner_name {
                  font-size: 10px !important;
                }
              }
              .winner_two {
                .winner_img {
                }

                .winner_points {
                  font-size: 10px !important;
                }
                .winner_name {
                  font-size: 10px !important;
                }
              }
            }
          }

          .winner_details {
            padding: 12px 17px;

            .winner_list {
              gap: 7px;
              max-height: 100% !important;
              overflow-y: auto;

              .winner_card {
                // display: flex;
                // align-items: center;
                // justify-content: center;
                padding: 5px 8px;
                width: 100%;
                height: 44px;

                .icons {
                  span {
                    font-size: 12px;
                    font-style: normal;
                    margin: 0 10px;
                    font-weight: 500;
                    line-height: 24px;
                  }

                  img {
                    // width: ;
                    // height: ;
                  }
                }
                .name {
                  span {
                    font-size: 12px;
                    margin-right: 15px;
                  }
                }
              }
            }
          }
          .heading {
            h1 {
              font-size: 21px !important;
            }
            .gray_button {
              padding: 0px 10px;
              // min-width: 56px ;
              width: 130px !important;
              font-size: 10px;
              font-weight: 400;
            }
          }
        }
      }

      .Subscription_Content {
        .card_section {
          height: 100%;
          gap: 40px;
          padding: 20px;

          .heading {
            width: 100%;
            h1 {
              font-size: 26px !important;
              font-weight: 700;
              span {
                font-size: 26px !important;
                font-weight: 700;
              }
            }
          }

          .cards {
            flex-direction: column;
            padding: 0;

            .subscription_card {
              width: 100%;
              margin: 0 !important;

              height: 100% !important;

              .top_card {
                h1 {
                  font-size: 26px !important;
                  font-weight: 700 !important;
                }
              }

              .card_content {
                .card_description {
                  h1 {
                    font-size: 18px !important;
                    font-weight: 500;
                  }

                  p {
                    margin-left: 10px;
                    margin-top: 5px;
                  }
                }
              }
            }
          }

          .question {
            margin: 0 !important;
            width: 70%;
          }
        }
      }

      .DiscussionForm_Content {
        .discussion_form_section {
          margin-top: 20px;
          .discussion_img {
            // width: 100% !important;
            // height: 20vh !important;
            // display: none;

            img {
              height: 100%;
            }
          }

          .join-btn {
            display: flex;

            justify-content: center;
            align-items: center;
            width: 180px;
            height: 50px;
          }
        }

        .discussion_community {
          padding: 0 20px !important;
          .ask_question_btn {
            margin-bottom: 20px;

            .askbtn {
              margin-bottom: 0 !important;
            }

            h1 {
              font-size: 25px !important;
            }
          }

          .discussion_cards {
            grid-template-columns: auto;
          }
        }
        .discussion_details_main_sec {
          padding: 20px;
          .discussion_details_sec {
            padding: 0px;
            flex-direction: column;

            // gap: 30px;

            .question_box {
              padding: 15px;

              .question_box_content {
                font-size: 12px;
                line-height: 17px;
              }
              .question_box_heading {
                font-size: 14px;
                line-height: 18px;
              }
            }

            .question_sec {
              width: 100%;

              .comment_box {
                padding: 15px;
              }

              .answer_box {
                padding: 0 0px;
                p {
                  font-size: 12px;
                }
                .answer_bottom {
                  padding: 8px 0;
                  span {
                    font-size: 12px;
                  }
                }
              }
            }

            .related_question_sec {
              // background-color: #07c290;
              width: 100%;
              padding: 0;

              .related_questions_boxes {
                flex-direction: column;

                gap: 10px;
              }
              .related_question_heading {
                padding: 0 10px;
                margin: 30px 0 10px 0;
              }
            }
          }
        }

        .discussion_card {
          padding: 17px 17px 5px 17px;
          h2 {
            font-size: 15px !important;
            // font-weight: 600;
          }

          p {
            font-size: 12px;
          }
        }
      }
      // User Level mobile responsive

      .UserLevel_Content {
        .user_level {
          padding: 15px 16px !important;
          display: block !important;

          // background-color: red;
          .achievements {
            padding: 0 !important;
            width: 100%;

            .profile {
              gap: 1px !important;

              h1 {
                font-size: 20px !important;
              }
              .user_data {
                h2 {
                  font-size: 20px !important;
                }
                h3 {
                  font-size: 20px !important;
                }
              }
              .profile_img {
                img {
                  width: 65%;
                  height: 65%;
                }
              }
            }
            .levels {
              max-width: 100% !important;
              .level_points {
                .level_heading {
                  h1 {
                    font-size: 16px !important;
                  }
                  span {
                    font-size: 12.5px !important;
                  }
                }
              }
            }
          }

          .activity_and_certification {
            // background-color: green;
            padding-left: 20px;

            h4 {
              text-align: center !important;
              font-size: 16px !important;
            }
            .strongest {
              gap: 28px !important;
              margin-bottom: 30px;
              // margin-right: 10px;

              .strongest_card_wrapper {
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                .strongest_card {
                  padding: 22px 16px;
                  // display: flex;
                  justify-content: center !important;
                  align-items: center !important;

                  .strongest_box {
                    gap: 8px;

                    .strong_img {
                      width: 43px;
                      height: 33px;
                    }

                    .heading {
                      .back_end_btn {
                        img {
                          width: 140px;
                        }
                        h2 {
                          font-size: 9px !important;
                        }
                        h3 {
                          font-size: 9px !important;
                        }
                        .back_end_btn_img {
                          display: flex;
                          align-items: center;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .center_line_img {
            display: none;
          }
          .activity_and_certification {
            margin-top: 35px !important;
            width: 100%;
            padding-left: 0px !important;
            padding-right: 0px;
            height: 100%;

            .certification {
              justify-content: center;
            }

            .strongest {
              margin-top: 25px;
            }

            .activity {
              max-width: 100% !important;
              justify-content: center;

              .activity_card {
                width: 47% !important;
                height: 80px !important;
                justify-content: space-evenly;
                padding: 24px 35px !important;

                .points {
                  h1 {
                    font-size: 15px !important;
                    line-height: normal;
                  }
                  span {
                    font-size: 9px !important;
                  }
                }
              }
            }
            .strongest {
              .strongest_card_wrapper {
                .strongest_card {
                  width: 95% !important;
                  padding: 15px 20px !important;

                  .strongest_box {
                    gap: 23px !important;

                    .heading {
                      h1 {
                        font-size: 14px !important;
                      }
                    }
                  }
                }
              }
            }
            .medals {
            }
          }
          .medals {
            h5 {
              font-size: 16px !important;
              text-align: center !important;
            }
            .medal_cards {
              flex-wrap: nowrap !important;
              gap: 5px !important;
              // justify-content: center;

              .medal_card {
                width: 32% !important;
                height: 94px !important;
                padding: 13px 18px !important;
                h1 {
                  font-size: 15px !important;
                }
                .bronze_btn {
                  width: 25px !important;
                  height: 17px !important;
                  font-size: 9px !important;
                }
                .silver_btn {
                  width: 25px !important;
                  height: 17px !important;
                  font-size: 9px !important;
                }
                .gold_btn {
                  width: 25px !important;
                  height: 17px !important;
                  font-size: 9px !important;
                }
              }
            }
          }
        }
      }

      .UserProfile_Content {
        .profile_content {
          flex-direction: column;
          padding: 20px !important;
          gap: 20px;
          width: 100%;
          height: 100%;

          .profile_section {
            width: 100%;
            gap: 10px;

            &::after {
              display: none;
            }

            .user_prof_heading {
              font-size: 18px;
            }

            .profile_img {
              width: 80px;
              height: 80px;

              .ProfileIcon {
                width: 35px;
                height: 25px;
              }
            }

            .name_desig {
              h6 {
                font-size: 16px;
              }
              p {
                font-size: 12px;
              }
            }

            .details_sec {
              gap: 30px;

              .position {
                position: relative;

                &::after {
                  height: 28px;
                  top: 8px;
                  right: -13px;
                }

                h6 {
                  font-size: 14px;
                }

                p {
                  font-size: 10px;
                }
              }

              .points {
                position: relative;

                &::after {
                  height: 28px;
                  top: 8px;
                  right: -15px;
                }

                h6 {
                  font-size: 14px;
                  span {
                    font-size: 10px;
                  }
                }
              }

              .following {
                h6 {
                  font-size: 14px;
                }

                p {
                  font-size: 10px;
                }
              }
            }

            .user_prof_footer {
              gap: 10px;

              Button {
                font-size: 11px;
              }

              h6 {
                font-size: 14px;
              }

              .user-bio {
                margin-top: -7px;
                max-width: 210px;
                p {
                  font-size: 10px;
                }
              }
            }
          }
        }

        .profile_form_section {
          width: 100%;
          padding: unset !important;

          form {
            width: 100%;
          }

          .basic_info {
            .basic_info_heading {
              h6 {
                font-size: 14px;
              }
            }

            .basic_info_btn {
              Button {
                font-size: 10px;
              }
            }
          }

          .input_flex {
            margin-bottom: unset !important;

            label {
              font-size: 11px !important;
            }

            input {
              padding: 5px 10px !important;
              font-size: 10px;
            }
          }

          .first_last {
            padding-top: 10px !important;
            gap: unset !important;
            flex-direction: column;

            .firstname {
              width: 100% !important;
            }
          }

          .about_me {
            padding-bottom: 10px !important;
            h6 {
              font-size: 14px !important;
              padding-bottom: unset !important;
            }
          }

          .about_me_input {
            textarea {
              font-size: 10px !important;
            }
          }

          .logout_btn {
            Button {
              font-size: 10px;
            }
          }
        }
      }

      .ContactUs_Content {
        .back {
          margin-left: 10px !important;
        }
        .contact_us_section {
          padding: 40px 10px !important;
          .contact_us_form {
            padding: 20px;
            h1 {
              font-size: 30px !important;
            }
            width: 96%;
          }
          .contact_us_img {
            display: none;
          }
        }
        .support_section {
          padding: 20px 20px;
          .support_heading {
            padding: 0 10px;

            h1 {
              font-size: 28px !important;
            }
          }
          .cards_heading {
            h1 {
              font-size: 24px !important;
            }
          }
          .support_cards {
            grid-template-columns: auto;
          }
        }

        .support_card {
          h1 {
            font-size: 20px !important;
          }
        }
      }

      .Report_Bugs {
        .contact_us_section {
          padding: 40px 10px !important;
          .contact_us_form {
            padding: 20px;
            h1 {
              font-size: 30px !important;
            }
            width: 96%;
          }
          .contact_us_img {
            display: none;
          }
        }
      }
    }
  }

  .view_hint_modal {
    .modal-content {
      width: 100% !important;
      height: 50% !important;

      background-color: transparentize($color: rgba(0, 0, 0, 0.5), $amount: 0);
      padding-bottom: 5px;
    }

    .modal-body {
      .modal_question {
        padding: 33px 28px;
        background: #f9f9f9;
      }
      .answer_code {
        border: 2px solid #f9f9f9;
        color: #f9f9f9;
        padding: 20px 20px;
        margin-top: 8px;

        // margin-bottom: 8px;
        // height: 85%;
      }
    }
  }

  .level_modal {
    .modal-content {
      background-color: transparentize($color: rgba(0, 0, 0, 0.5), $amount: 0);
      width: 564px;
      height: 230px;

      // width: 564px !important;
      // height: 250px;
      .modal-body {
        .levels {
          display: flex;
          flex-direction: column;
          gap: 10px;
          text-align: center;
          align-items: center;

          justify-content: center;

          .soft {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            width: 100% !important;
            // height: 100% !important;
            // width: 340px;
            background: #ffe600;
            color: #fff;
          }

          .easy {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100% !important;
            // height: 100% !important;
            // width: 340px;
            height: 59px;
            border-radius: 4px;
            background: #07c290;
            color: white;
          }

          .hard {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100% !important;
            // height: 100% !important;
            // width: 340px;
            height: 59px;
            border-radius: 4px;
            background: #ee2a59;
            color: white;
          }
        }
      }
    }
  }

  // <---------------- Components Style ---------------->
  .topbar {
    .top {
      padding: 20px 20px 20px 10px !important;
      gap: 0px;

      width: 100% !important;
      .profile_section {
        gap: 15px;
        .search_icon {
          display: block;
        }

        .notification_section {
          z-index: 10;

          .notifications {
            // left: 5px;
            // right: 10px;
          }

          img {
            width: 20px;
          }
        }

        .profile {
          justify-content: end;
          gap: 6px;
          span {
            display: none;
            font-size: 12px;
            width: 120px;
          }

          .icon {
            display: none;
          }
          .profile_modal {
            z-index: 100;
            // width: ;
          }

          .profile_img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .search_section {
        gap: 2px !important;
        width: 60%;
        // .back_btn {
        //   width: 35px !important;
        //   height: 35px !important;
        // }

        h1 {
          // display: none;
          font-size: 20px !important;
          width: 110%;
        }

        .search_bar {
          display: none;
        }

        .side_menu_icon {
          display: flex;
        }
      }
    }

    .bottom {
    }
    .showtopbar {
      // transform: translateY(0%);
      // transition: transform 0.5s ease; /* Adjust the duration and timing function as needed */
      display: flex !important;
      margin-top: 95px;
      margin-bottom: -95px;
      padding-bottom: 20px;
    }

    .hidetopbar {
      // transform: translateY(-100%);
      // transition: transform 0.5s ease; /* Adjust the duration and timing function as needed */
      display: none;
    }
  }

  .logout_btn {
    min-width: 143px;
    min-height: 36px;
    font-size: 16px;
  }
}
