@font-face {
  font-family: "JK Abode";
  src: url("../../public/font/JKAbode-Demo.ttf");
}
@font-face {
  font-family: "IRANSansX";
  src: url("../../public/font/Jomhuria-Regular.ttf");
}
@font-face {
  font-family: "Jomhuria";
  src: url("../../public/font/Jomhuria-Regular.ttf");
}
@font-face {
  font-family: "Volkhov";
  src: url("../../public/font/Volkhov-Regular.ttf");
}

@font-face {
  font-family: "Vollkorn";
  src: url("../../public/font/Vollkorn-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Volkhov_italic";
  src: url("../../public/font/Volkhov-Italic.ttf");
}

@font-face {
  font-family: "Lato";
  src: url("../../public/font/Lato-Regular.ttf");
}

$primary: #000000;
// $primary: #2b54ff;

.navbar {
  // padding: 0 !important;
}

.nav_bar {
  display: flex;
  width: 100%;
  justify-content: space-between !important;
  align-items: center;
  background-color: $primary;
  padding: 10px 200px 10px 100px;
  .center_nav {
    flex-grow: 0 !important;
  }
  .logo {
    // background-color: green;
    // margin-right: -60px !important;
    // margin-left: 50px;
    // color: #fff;
    // font-family: "Jomhuria", sans-serif;
    // font-size: 64px;
    // font-style: normal;
    // font-weight: 400;
    // line-height: normal;

    width: 37%;
    img {
      width: 35%;
    }
  }

  .links {
    display: flex;
    justify-content: space-between !important;
    flex-grow: 0 !important;
    align-items: center;
    gap: 100px;
  }

  .list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
  }

  .list_link {
    font-family: "IRANSansX", sans-serif;
    color: #fff;
    font-size: 16px;
  }

  .list_link:hover {
    border-bottom: 1px solid #fff;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }

  .active a {
    border-bottom: 1px solid #fff;
  }

  .navbar_toggle {
    display: none;
  }
}

.home {
  .learn_section {
    // background-image: url("../assets/image/home/Learn_bg.png");
    background-color: $primary;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding: 200px 120px 50px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .top {
      display: flex;
      gap: 80px;
      justify-content: space-around;
      align-items: center;

      img {
        width: 40%;
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 40px;

        h1 {
          color: white;
          font-family: "Volkhov", sans-serif;
          font-size: 4.7vw;
          font-style: normal;
          font-weight: 400;
          // max-width: 660px;
          span {
            color: #43dde7;
            display: block;
          }
        }

        P {
          color: white;
          font-family: "IRANSansX" sans-serif;
          font-size: 19px;
          // font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 10px;
        }

        .buttons {
          display: flex;
          gap: 10px;
        }
      }
    }

    .bottom {
      margin-top: 70px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        color: #8fa9b1;

        font-family: "Volkhov", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 15px;

        a {
          color: #8fa9b1;
          font-family: "Volkhov" sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
        }
      }
      .social_links {
        display: flex;
        gap: 10px;
      }
    }
  }

  .benefit_banner {
    padding: 145px 300px;
    margin: 0;
    background-color: $primary;
    // background-image: url("../assets/image/home/Benefit_Banner.png");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    position: relative;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    color: white;

    .top_right {
      position: absolute;
      top: 0;
      right: 0;
      width: 400px;
      img {
        width: 100%;
      }
    }

    .bottom_left {
      position: absolute;

      bottom: 0;
      left: 0;
    }

    h1 {
      color: #fff;

      font-family: "Volkhov";
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
    }

    p {
      font-family: "IRANSansX";
      font-size: 20px;
      width: 100%;
      padding-right: 10%;
      color: #43dde7;
    }

    .cards {
      margin-top: 40px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      // flex-wrap: wrap;
      gap: 30px;

      .card {
        background-color: $primary;
        color: white;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 400px;
        border: none;
        // margin-left: 4%;
        img {
          width: 80px;
          // margin: auto;
        }

        h2 {
          font-family: "IRANSansX", sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
        }

        span {
          color: #8fa9b1;
          font-family: Lato;
        }

        p {
          font-family: "IRANSansX", sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }

  .help_section {
    display: flex;
    justify-content: space-between;
    position: relative;
    .right_img {
      position: absolute;
      width: 24%;
      height: 100%;
      // border: 1px solid red;
      right: 0;
      top: 0;
      // width: 400px;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .left_img {
      // width: 70%;
      // border: 1px solid red;
      // margin: 0 0 90px 0;
      position: absolute;
      width: 24%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      img {
        width: 100%;
        height: 100%;
      }

      // max-width: 50%;
    }

    .content {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      // padding: 40px;

      .content_top {
        text-align: center;
        // padding: 30px 15px 0 20px;
        padding: 76px 17% 44px 17%;

        h1 {
          color: #000;
          font-family: "Volkhov", sans-serif;
          font-size: 44px;
          margin-bottom: 20px;
          font-weight: 400;

          span {
            color: $primary;
            font-family: "Volkhov", sans-serif;
            font-size: 44px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-transform: uppercase;
          }
        }

        p {
          color: #000;
          text-align: center;
          font-family: "IRANSansX", sans-serif;
          font-size: 20px;
          font-weight: 400;
        }
      }
      .content_bottom {
        margin-top: 10px;
        flex-wrap: wrap;
        // gap: 58px;
        padding: 20px 24% 44px 24%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
      }
    }

    .help_card {
      display: flex;
      // flex-direction: column;
      justify-content: space-between;
      gap: 50px;
      // width: 450px;

      padding: 5px 15px 15px 15px;
      .help_card_content {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        padding: 20px 0;
        width: 50%;
        // height: 170px !important;
        h1 {
          font-family: Volkhov_italic;
          font-size: 34px;
          // font-style: italic;
          font-weight: 400;
        }

        p {
          color: #000;
          width: 90%;
          font-family: "IRANSansX", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
      }

      .help-card_img {
        // max-width: 500px;
        width: 50%;

        img {
          width: 90%;
          object-fit: cover;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);
          border-radius: 5px;

          // background-color: red;
        }
      }
    }

    // .updated_help_card {
    //   display: flex;
    //   gap: 50px;
    //   justify-content: space-between;

    //   .updated_help_card_img {
    //     max-width: 500px;

    //     img {
    //       width: 90%;
    //       object-fit: cover;
    //       box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);
    //       border-radius: 5px;

    //       // background-color: red;
    //     }
    //   }

    //   .updated_help_card_content {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     height: 170px !important;
    //     h1 {
    //       font-family: "Volkhov", sans-serif;
    //       font-size: 34px;
    //       font-style: italic;
    //       font-weight: 400;
    //     }

    //     p {
    //       color: #000;
    //       width: 90%;
    //       font-family: "IRANSansX", sans-serif;
    //       font-size: 14px;
    //       font-style: normal;
    //       font-weight: 400;
    //     }
    //   }
    // }
  }

  .practice_banner {
    // background-image: url("../assets/image/home/SQL_Banner.png");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: 100% 100%;
    .video_ {
      padding: 20px 0 20px 0;
      background-color: #000000 !important;
      width: 100%;

      video {
        width: 60%;
      }
    }
    background-color: $primary;
    padding: 100px 0;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    width: auto;
    color: white;
    // margin: 20px 0;
    position: relative;

    .practice_top_right {
      max-width: 300px;
      position: absolute;
      top: 0;
      right: 0;
      img {
        width: 100%;
      }
    }

    .bottom_left {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    h1 {
      text-align: center;
      font-family: Volkhov;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    p {
      text-align: center;
      // font-family: IRANSansX;
      font-size: 20px;
      width: 63%;
      color: #8fa9b1;
    }

    .logos {
      width: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 50px;
      margin: 30px 0;
      .logo {
        padding: 30px;
        border: 3px solid white;
        border-radius: 8px;
        height: 250px !important;
        height: 300px;
        width: 250px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          // height: 78px;
          // width: 78px;
        }
      }

      .logo:hover {
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);
        transition: 400ms ease;
      }
    }
  }

  .community_section {
    padding: 80px 100px;

    .join_us {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;
      margin-bottom: 30px;

      img {
        width: 45%;
      }

      .content {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: center;
        // gap: 30px;

        h1 {
          text-align: end;
          font-family: Volkhov;
          font-size: 64px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          span {
            color: $primary;
            display: block;
          }
        }

        p {
          width: 90%;
          text-align: right;
          font-size: 20px;
          font-family: "IRANSansX", sans-serif;
          margin-bottom: 35px;
        }

        .join_btn {
          width: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .cards {
      padding: 0 50px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 50px;

      .card {
        font-family: "IRANSansX", sans-serif;
        fill: #fff;
        background-color: white;
        filter: drop-shadow(2px 6px 16px rgba(39, 43, 76, 0.2));
        padding: 30px 40px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        max-width: 40%;
        border: 2px solid transparent;

        .header {
          display: flex;
          justify-content: space-between;
          // align-items: center;

          .card_profile {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;

            .profile_img {
              height: 50px;
              width: 50px;
              object-fit: cover;
              border-radius: 50%;
            }

            .profile_content {
              display: flex;
              align-items: start;
              gap: 10px;

              h1 {
                font-size: 16px;
              }

              p {
                font-size: 10px;
              }
            }
          }
        }

        .social_links {
          display: flex;
          align-items: center;
          gap: 75px;

          img {
            margin-right: 5px;
          }
        }
      }

      .card:hover {
        border: 2px solid $primary;
        transition: 300ms ease;
      }
    }
  }

  .getstarted_banner {
    color: white;
    background-image: url("../assets/image/home/GetStarted2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 120px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        text-align: center;
        font-family: Volkhov;
        font-size: 44px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 10px;
      }

      p {
        font-size: 20px;
        font-family: IRANSansX;
        margin-bottom: 27px;
      }
    }
  }

  .contact_us {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 21px;
    padding: 60px 100px;
    color: #0a142f;

    .upskill {
      // letter-spacing: 3.728px;
      color: var(--Dark, #0a142f);
      text-align: center;
      font-family: IRANSansX;
      font-size: 18.64px;
      font-weight: 400;
      letter-spacing: 3.728px;
      text-transform: uppercase;
      opacity: 0.7;
      margin-bottom: 5px;
    }

    h1 {
      text-align: center;
      font-family: Vollkorn;
      font-size: 64px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    p {
      font-family: "IRANSansX", sans-serif;
      text-align: center;
      font-size: 20px;
      width: 100%;
    }

    span {
      font-size: 18.64px;
      font-family: "IRANSansX", sans-serif;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 5px;
      margin-bottom: -30px;
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 60px 170px 0;
  border-top: 2px solid black;
  padding: 35px 0;

  .footer_logo {
    width: 200px;
    img {
      width: 100%;
    }
  }

  .logo {
    color: $primary;

    font-family: Jomhuria;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .links {
    font-family: "IRANSansX", sans-serif;
    display: flex;
    gap: 40px;
    font-size: 20px;

    a {
      color: black;
    }
    a:hover {
      color: #43dde7;
    }
  }

  .social_links {
    display: flex;
    gap: 10px;
  }
}

// <------------------------- Components Style ---------------------->

.button {
  font-family: "IRANSansX", sans-serif;
  background-color: white;
  color: $primary;
  // border: 2px solid white;
  outline: none;
  border-radius: 40px;
  width: 112px;
  height: 44px;
  font-weight: 600;
  font-size: 17.725px;
  margin-right: -120px;
}

.button:hover {
  color: white;
  background-color: #43dde7;
  transition: 500ms ease;
}

.active_button {
  color: white;
  border-radius: 40px;
  background: #43dde7;
  // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);
  border: none;
  outline: none;
  padding: 12px 30px;
  font-size: 18px;
}

.join_btn {
  color: white;
  border-radius: 40px;
  background: $primary;
  // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);
  border: none;
  outline: none;
  // padding: 12px 30px;
  height: 63px;
  text-align: center;
  font-family: "IRANSansX", sans-serif;
  font-size: 24px;
}

.join_btn:hover {
  color: white;
  background-color: #43dde7;
  transition: 500ms ease;
}

.stroke_button {
  color: #fff;
  font-family: "IRANSansX", sans-serif;
  font-size: 17.725px;
  font-style: normal;
  font-weight: 500;
  outline: #fff;
  background: $primary;
  height: 43px;
  width: 150px;
  border: 2px solid #fff;
  border-radius: 35px;
}

.stroke_button:hover {
  background-color: white;
  color: $primary;
  transition: 800ms ease;
}

.stroke_active {
  color: black;
  // color: $primary;
  font-family: "IRANSansX", sans-serif;
  font-size: 17.725px;
  font-style: normal;
  font-weight: 500;
  outline: $primary;
  background: #fff;
  padding: 10px 25px;
  // border: 2px solid $primary;
  border: 2px solid black;
  border-radius: 35px;
}
.stroke_active:hover {
  color: white;
  background-color: #43dde7;
}

.hover_btn {
  color: #fff;
  outline: #fff;
  background: $primary;
  padding: 10px 0px;
  border: 2px solid #fff;
  border-radius: 35px;
  min-width: 200px;
  text-align: center;
  font-family: IRANSansX;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hover_btn:hover {
  background-color: white;
  color: $primary;
  transition: 800ms ease;
}

.shadow_button {
  color: white;
  border-radius: 40px;
  background: $primary;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);
  border: none;
  outline: none;
  padding: 12px 0px;
  margin-bottom: 60px;
  font-size: 18px;
  height: 63px;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shadow_button:hover {
  color: white;
  background-color: #43dde7;
  transition: 500ms ease;
}

// .hide{
//   display: none;
// }
// <-------------------------- Meida Queries ------------------------>

@media screen and (min-width: 1501px) and (max-width: 1830px) {
  .nav_bar {
    // padding: 10px 100px;
  }

  .home {
    .learn_section {
      // padding: 286px 175px 50px 308px;
      padding: 200px 120px 40px 100px;
      .top {
        gap: 65px;
        .content {
          h1 {
            // font-size: 80px;
          }
          p {
            font-size: 16px;
            // line-height: 30px;
          }
        }
        img {
          width: 40%;
        }
      }
    }

    .benefit_banner {
      // padding: 250px !important;
      // background-size: 100% 100% !important;
      padding: 100px 250px;

      .top_right {
        width: 380px;
      }

      .cards {
        // flex-wrap: nowrap !important;
        justify-content: space-between;
        .card {
          // max-width: 300px;
        }
      }
    }

    .help_section {
      .left_img {
        // width: 70%;
      }

      .right_img {
        // width: 70%;
      }

      .content {
        .content_top {
          padding: 76px 15% 44px 15%;

          h1 {
            font-size: 50px;
            span {
              font-size: 50px;
            }
          }
          p {
            font-size: 17px;
            // font-size: 14px !important;
          }
        }
        .content_bottom {
          // flex-wrap: wrap;
          // display: flex;
          // justify-content: space-between;
          // align-items: center;
        }
      }
    }

    .help_card {
      // max-width: 350px;
      // padding: 0 !important;

      .help_card_content {
        p {
          width: 100% !important;
        }
      }
    }

    .practice_banner {
      // padding: 250px 0 !important;
      background-size: 100% 100% !important;
      h1 {
        font-size: 40px;
      }
      p {
        font-size: 18px;
      }
    }

    .community_section {
      .join_us {
        .content {
          h1 {
            font-size: 54px;

            span {
              font-size: 54px;
            }
          }
        }
      }
    }

    .contact_us {
      h1 {
        font-size: 54px;
      }
    }
    .getstarted_banner {
      h1 {
        font-size: 42px !important;
      }
    }
  }

  // .home .help_section .content .content_bottom {
  //   justify-content: center !important;
  // }
}

@media screen and (max-width: 1730px) {
  .home {
    .learn_section {
      padding: 200px 100px 40px 100px;

      .top {
        img {
          width: 36% !important;
        }
      }
    }

    .benefit_banner {
      padding: 100px 235px;
      .top_right {
        width: 340px;
      }

      p {
        font-size: 18px;
        padding-right: 8%;
      }
    }

    .practice_banner {
      .video_ {
        video {
          width: 56%;
        }
      }
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .nav_bar {
    padding: 10px 200px 10px 70px;

    .logo {
      width: 40%;
    }
  }
  .home {
    .learn_section {
      padding: 150px 75px 40px 75px;
      .top {
        gap: 45px;
        .content {
          h1 {
            // font-size: 50px;
          }

          p {
            font-size: 14px;
            // line-height: 30px;
          }

          .buttons {
          }
        }
        img {
          width: 33% !important;
        }
      }

      .bottom {
        .social_links {
          img {
            width: 60px;
          }
        }
      }
    }

    .benefit_banner {
      // padding: 250px !important;
      // background-size: 100% 100% !important;
      padding: 100px 180px;
      gap: 20px;
      .top_right {
        width: 280px;
      }
      h1 {
        font-size: 38px;
      }

      p {
        font-size: 16px;
        width: 100%;
        padding-right: 8%;
      }

      .cards {
        // flex-wrap: nowrap !important;
        justify-content: space-between;
        flex-wrap: nowrap;
        .card {
          // max-width: 260px;

          h2 {
            font-size: 16px;
          }
        }
      }
    }

    .help_section {
      .left_img {
        // width: 80%;
      }

      .right_img {
        // width: 80%;
      }

      .content {
        .content_top {
          padding: 20px 15% 44px 15%;

          h1 {
            font-size: 40px;
            span {
              font-size: 40px;
            }
          }
          p {
            font-size: 14px;
          }
        }
      }
    }

    .help_card {
      // max-width: 280px;

      .help_card_content {
        // height: 0 !important;

        h1 {
          font-size: 28px !important;
        }

        p {
          font-size: 12px !important;
        }
      }
    }

    .practice_banner {
      // padding: 250px 0 !important;
      // background-size: 100% 100% !important;

      .practice_top_right {
        max-width: 230px;
      }
      h1 {
        font-size: 35px;
      }
      p {
        font-size: 14px;
      }

      .logos {
        flex-wrap: nowrap;
      }
    }

    .community_section {
      .join_us {
        .content {
          h1 {
            font-size: 54px;

            span {
              font-size: 54px;
            }
          }
        }
      }

      .cards {
        padding: 0 0;
        .card {
          max-width: 45%;

          .social_links {
            gap: 50px;
          }
        }
      }
    }

    .contact_us {
      h1 {
        font-size: 54px;
      }
    }
    .getstarted_banner {
      h1 {
        font-size: 42px !important;
      }
    }
  }

  .footer {
    margin: 60px 100px 0;
    .social_links {
      margin-left: 10px;
      img {
        width: 50px;
      }
    }
  }

  .links {
    gap: 30px !important;
    font-size: 17px !important;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  .nav_bar {
    justify-content: space-between;
    // padding: 0 100px 0 60px;
    .logo {
      width: 43%;
    }
    padding: 10px 200px 10px 70px;
    .links {
      // display: none;
      .list {
        align-items: flex-start;
      }
    }
    .buttons {
      gap: 10px;
      margin-left: 0 !important;
      justify-content: start;
      padding: 15px 0;
    }
    .navbar_toggle {
      display: none;
    }
  }

  .home {
    .learn_section {
      padding: 150px 75px 40px 60px !important;
      .top {
        gap: 45px;
        .content {
          h1 {
            // font-size: 50px;
          }

          p {
            font-size: 13px;
            // line-height: 30px;
          }

          .buttons {
          }
        }
        img {
          width: 27% !important;
        }
      }
      .bottom {
        .social_links {
          img {
            width: 60px;
          }
        }
      }
    }

    .help_section {
      // padding: 30px 130px;
      .left_img {
        display: none;
      }
      .right_img {
        display: none;
      }
      .content {
        .content_top {
          padding: 76px 9% 44px 9%;
          h1 {
            // background-color: red;
            // font-size: 40px;
            span {
              // font-size: 55px;
            }
          }
          p {
            font-size: 14px;
          }
        }

        .content_bottom {
          justify-content: center;
          padding: 20px 20% 44px 20%;

          text-align: left;
          // padding: 0 !important;
        }
      }
    }

    .help_card {
      // max-width: 320px;
      // background-color: red;
      .help-card_img {
        img {
          width: 100%;
        }
      }
      .help_card_content {
        h1 {
          // background-color: red;
        }
      }
    }

    .community_section {
      padding: 30px 80px !important;

      .join_us {
        img {
          width: 40% !important;
        }

        .content {
          h1 {
            font-size: 54px !important;
          }
        }
      }

      .cards {
        padding: 0 !important;
        gap: 30px !important;

        .card {
          max-width: 48% !important;
          .social_links {
            gap: 50px;
          }
        }
      }
    }

    .benefit_banner {
      // background-image: none !important;
      // background-color: $primary !important;
      padding: 100px 130px !important;

      .top_right {
        display: none;
      }

      .bottom_left {
        display: none;
      }

      h1 {
        font-size: 38px;
      }

      p {
        font-size: 14px;
        padding-right: 0;
      }

      .top_right {
        width: 300px;
      }

      .cards {
        justify-content: space-between !important;
        flex-wrap: nowrap;
        .card {
          max-width: 250px;

          img {
            width: 60px;
          }

          h2 {
            font-size: 18px;
          }

          p {
            font-size: 16px;
          }
        }
      }
    }

    .practice_banner {
      background-color: $primary;
      // padding: 30px 20px !important;
      // background-image: none !important;
      .practice_top_right {
        display: none;
      }

      .bottom_left {
        display: none;
      }

      h1 {
        font-size: 35px;
      }
      p {
        width: 72%;
        font-size: 15px;
      }

      .logos {
        flex-wrap: nowrap;
      }
    }

    .getstarted_banner {
      h1 {
        font-size: 42px !important;
      }
    }

    .contact_us {
      h1 {
        font-size: 54px;
      }
    }
  }

  .footer {
    justify-content: space-around;

    gap: 30px;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .nav_bar {
    justify-content: space-between;
    padding: 0 130px;

    .links {
      // display: none;
      .list {
        align-items: flex-start;
      }
    }
    .buttons {
      gap: 10px;
      margin-left: 0 !important;
      justify-content: start;
      padding: 15px 0;
    }
    .navbar_toggle {
      // display: none;
    }
  }

  .home {
    .learn_section {
      padding: 15px 100px;
      height: 100%;
      .top {
        flex-direction: column;
        gap: 20px;
        // background-color: red;

        .content {
          text-align: center;
          h1 {
            font-size: 50px;
          }

          p {
            line-height: 26px;
            font-size: 18px;
          }

          .buttons {
            padding-bottom: 30px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // flex-wrap: wrap;

            .sql_pad_btn {
              width: 240px;
            }
          }
        }
        img {
          width: 60%;
        }
      }

      .bottom {
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    .benefit_banner {
      // background-image: none !important;
      // background-color: $primary !important;
      padding: 100px 120px !important;

      .top_right {
        display: none;
      }

      .bottom_left {
        display: none;
      }

      h1 {
        font-size: 32px;
      }

      p {
        font-size: 16px;
      }

      .cards {
        // justify-content: center;
        flex-wrap: wrap;
        .card {
          max-width: 250px;

          img {
            width: 60px;
          }

          h2 {
            font-size: 18px;
          }

          p {
            font-size: 16px;
          }
        }
      }
    }

    .help_section {
      .left_img {
        display: none;
        background-color: red($color: #000000);
      }

      .right_img {
        display: none;
      }

      .content {
        .content_top {
          h1 {
            font-size: 40px;
            font-weight: 400;

            span {
              font-size: 30px;
              font-weight: 400;
            }
          }

          p {
            // margin-top: -5%;
            font-size: 14px !important;
            text-align: center;
          }
        }
        .content_bottom {
          justify-content: center !important;
        }
      }

      .help_card {
        padding: 25px 0 !important;
        gap: 30px !important;

        .help_card_content {
          p {
            width: 100% !important;
            text-align: center;
          }
        }
      }
    }

    .help_card {
      max-width: 360px;
      // padding: 10px;
      //margin-bottom: 10px;
      align-items: center;
      justify-content: center !important;
      padding: 0 !important;
      .help-card_img {
        text-align: center;
        order: 2 !important;
        img {
          width: 40%;
          object-fit: cover;
          border-radius: 10px !important;
          // background-color: red;
        }
      }
      .help_card_content {
        order: 1 !important;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        // justify-content: center;

        h1 {
          text-align: center;
          font-size: 25px !important;
          margin-bottom: 10px;
        }
        p {
          // text-align: left;
          font-size: 16px;
          text-align: center;
        }
      }
    }

    .practice_banner {
      background-color: $primary;
      padding: 30px 20px !important;
      // background-image: none !important;

      .practice_top_right {
        display: none;
      }

      .bottom_left {
        display: none;
      }

      h1 {
        font-size: 30px !important;
        margin-bottom: 10px !important;
      }

      p {
        font-size: 16px !important;
        // width: 100% !important;
      }

      .logos {
        width: 100% !important;
        gap: 20px;
        .logo {
          max-width: 200px;
          min-height: 200px;
        }
        .logo img {
          // height: 100%;
        }
      }
    }

    .community_section {
      padding: 30px 60px !important;

      .join_us {
        img {
          width: 40% !important;
        }

        .content {
          width: 50% !important;

          h1 {
            font-size: 44px !important;
          }

          p {
            width: 60%;
            text-align: right;
            font-size: 18px !important;
          }
        }
      }

      .cards {
        padding: 0 !important;
        justify-content: start !important;
        gap: 10px !important;

        .card {
          padding: 30px 20px !important;
          max-width: 49% !important;

          .header {
            .card_profile {
              gap: 0px !important;

              .profile_img {
                height: 60px !important;
                width: 60px !important;
              }

              .profile_content {
                flex-wrap: wrap !important;
                gap: 5px !important;
              }
            }
          }
        }
      }
    }

    .getstarted_banner {
      height: 400px !important;

      h1 {
        font-size: 32px !important;
      }
    }

    .contact_us {
      padding: 60px 80px !important;
      h1 {
        font-size: 54px;
      }
    }
  }

  .footer {
    justify-content: space-around;
    gap: 30px;
  }
}

@media screen and (max-width: 600px) {
  .nav_bar {
    justify-content: space-between !important;
    padding: 0 20px;
    .logo {
      // font-size: 50px !important;
      padding-top: 10px;
      width: 35%;
      img {
        width: 100%;
      }
    }
    .links {
      // display: none;
      // display: none;
      .list {
        align-items: flex-start;
      }
    }
    .buttons {
      margin-left: 0 !important;
      gap: 10px;
      justify-content: start;
      padding: 15px 0;
    }
    .navbar_toggle {
      display: block;
    }
  }

  .home {
    .learn_section {
      background-image: none;
      padding: 20px 0px;
      // border-bottom: 1px solid red;
      height: 100%;
      .top {
        flex-wrap: wrap;
        width: 100%;
        padding: 10px 17px !important;

        // background-color: red;
        .content {
          text-align: center;
          gap: 22px !important;
          h1 {
            font-size: 32px !important;
          }
          p {
            font-size: 14px !important;
            line-height: 19px !important;
            padding: 13px 0 !important;
          }

          .buttons {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .sql_pad_btn {
              width: 178px;
            }
          }
        }

        img {
          width: 60%;
          display: none;
        }
      }

      .bottom {
        margin-top: 20px !important;
        p {
          padding: 10px;
          text-align: center;
          font-size: 17px;

          a {
            display: block;
            font-size: 17px !important;
          }
        }

        .social_links {
          width: 100%;
          padding: 16px;
          flex-wrap: wrap;
          justify-content: center;
          // flex-direction: column;
          img {
            width: 80%;
          }
        }
      }
    }

    .benefit_banner {
      background-color: $primary;
      padding: 30px 20px !important;
      // background-image: none !important;
      text-align: center;

      .top_right {
        display: none;
      }

      .bottom_left {
        display: none;
      }

      h1 {
        font-size: 24px !important;
        margin-bottom: 10px !important;
      }

      p {
        font-size: 12px !important;
        max-width: 350px !important;
        padding-right: 0 !important;
      }

      .cards {
        justify-content: center !important;
        text-align: center !important;
        gap: 50px !important;
        flex-wrap: wrap;
        margin-top: 30px;
        .card {
          width: 100% !important;
          justify-content: center;
          align-items: center;
          margin-left: 0 !important;
          gap: 12px !important;

          h2 {
            font-size: 18px !important;
          }
          img {
            margin: 0 auto;
            justify-content: center !important;
          }
          span {
            font-size: 12px !important;
          }
        }
      }
    }

    .help_section {
      .left_img {
        display: none;
        background-color: red($color: #000000);
      }

      .right_img {
        display: none;
      }

      .content {
        padding: 20px;
        .content_top {
          padding: 0px;
          h1 {
            font-size: 24px;
            font-weight: 400;

            span {
              font-size: 26px;
              font-weight: 400;
            }
          }

          p {
            // margin-top: -5%;
            font-size: 14px !important;
            text-align: center;
          }
        }
        .content_bottom {
          padding: 0;
          justify-content: center !important;
        }
      }

      .help_card {
        padding: 25px 0 !important;
        gap: 30px !important;
        flex-direction: column;

        .help_card_content {
          width: 100%;
          p {
            width: 100% !important;
            text-align: center;
          }
        }
        .help-card_img {
          width: 100%;
        }
      }
    }

    .help_card {
      // max-width: 360px;
      // padding: 10px;
      //margin-bottom: 10px;
      align-items: center;
      justify-content: center !important;
      padding: 0 !important;
      .help-card_img {
        text-align: center;
        order: 2 !important;
        img {
          width: 40%;
          object-fit: cover;
          border-radius: 10px !important;
          // background-color: red;
        }
      }
      .help_card_content {
        order: 1 !important;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        // justify-content: center;

        h1 {
          text-align: center;
          font-size: 25px !important;
          margin-bottom: 10px;
        }
        p {
          // text-align: left;
          font-size: 16px;
          text-align: center;
        }
      }
    }

    .practice_banner {
      background-color: $primary;
      padding: 30px 20px !important;
      // background-image: none !important;

      .video_ {
        video {
          width: 90%;
        }
      }

      .practice_top_right {
        display: none;
      }

      .bottom_left {
        display: none;
      }

      h1 {
        font-size: 26px !important;
        margin-bottom: 10px !important;
      }

      p {
        font-size: 14px !important;
        width: 100% !important;
      }

      .logos {
        gap: 25px !important;
        margin: 20px 0 !important;
      }
      .logo img {
        height: 90%;
      }
      .hover_btn {
        font-size: 18px !important;
        height: 45px !important;
      }
    }

    .community_section {
      padding: 30px 15px !important;

      .join_us {
        img {
          width: 100% !important;
          height: 100%;
          display: none;
        }

        .content {
          width: 100% !important;
          align-items: center !important;

          h1 {
            font-size: 30px !important;
            text-align: center !important;
          }

          p {
            width: 100% !important;
            text-align: center !important;
            font-size: 14px !important;
          }
          .join_btn {
            font-size: 20px !important;
            height: 45px !important;
          }
        }
      }

      .cards {
        padding: 0 !important;
        gap: 20px !important;

        .card {
          padding: 20px 15px !important;
          max-width: 100% !important;

          .header {
            .card_profile {
              flex-wrap: wrap !important;

              .profile_content {
                h1 {
                  font-size: 17px !important;
                }
              }
            }
          }

          .social_links {
            gap: 10px !important;
          }
          .details {
            p {
              font-size: 14px !important;
            }
            span {
              font-size: 16.5px !important;
            }
          }
        }
      }
    }

    .getstarted_banner {
      height: 150px !important;

      h1 {
        font-size: 26px !important;
      }

      p {
        font-size: 12px !important;
      }
      .hover_btn {
        min-width: 180px !important;
        height: 45px !important;
        font-size: 18px !important;
      }
    }

    .contact_us {
      padding: 30px 15px 0 15px !important;
      gap: 10px !important;

      h1 {
        font-size: 30px !important;
        text-align: center;
      }
      span {
        font-size: 17px !important;
        margin-bottom: 0 !important;
      }
      p {
        font-size: 14px !important;
      }
      .shadow_button {
        margin-bottom: 20px !important;
        height: 45px !important;
        min-width: 180px !important;
      }
    }
  }

  .footer {
    justify-content: center;
    gap: 20px;
    margin: 30px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0 !important;
    border-top: 1px solid black !important;

    .footer_logo {
      width: 150px;
    }

    .links {
      flex-direction: column;
      font-size: 15px;
      text-align: center;
      gap: 12px;
    }
    span {
      font-size: 50px !important;
    }
  }

  .active_button {
    padding: 10px 45px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }

  // stroke button
  .stroke_active {
    font-size: 14px;
    padding: 10px 15px;
  }
  .social_links {
    a {
      img {
        width: 40px !important;
        height: 40px;
      }
    }
  }
}
