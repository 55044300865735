.home_topbar {
  background-color: #40444b;
  padding: 12px;
  p {
    text-align: center;
    color: white;
  }
}

@media screen and (max-width: 600px) {
  .home_topbar {
    p {
      font-size: 13px;
    }
  }
}
